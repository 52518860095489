import { getToken, returnError } from './helperFunc';
import {
  getPromotionListAction,
  addPromotionAction,
  assignPromotionAction,
  editPromotionAction,
  deletePromotionAction,
} from './requestEndpoints';

import { LOGOUT_SUCCESS } from './userReducer';

const SET_PROMOTION_LOADING = 'SET_PROMOTION_LOADING';
const CLEAR_PROMOTION_ERROR = 'CLEAR_PROMOTION_ERROR';

const ADD_PROMOTION_SUCCESS = 'ADD_PROMOTION_SUCCESS';
const ADD_PROMOTION_FAIL = 'ADD_PROMOTION_FAIL';

const ASSIGN_PROMOTION_SUCCESS = 'ASSIGN_PROMOTION_SUCCESS';
const ASSIGN_PROMOTION_FAIL = 'ASSIGN_PROMOTION_FAIL';

const GET_PROMOTION_LIST_SUCCESS = 'GET_PROMOTION_LIST_SUCCESS';
const GET_PROMOTION_LIST_FAIL = 'GET_PROMOTION_LIST_FAIL';

const DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS';
const DELETE_PROMOTION_FAIL = 'DELETE_PROMOTION_FAIL';

const UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCESS';
const UPDATE_PROMOTION_FAIL = 'UPDATE_PROMOTION_FAIL';

// FOR PRICE SETTING

export const getPromotionList = (operatorId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PROMOTION_ERROR });
    dispatch({ type: SET_PROMOTION_LOADING });

    const { user } = getState();
    // console.log('user', user);
    const token = getToken(user);

    const { data } = await getPromotionListAction(operatorId, token);

    dispatch({ type: GET_PROMOTION_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_PROMOTION_LIST_FAIL, payload: returnError(error) });
  }
};

export const addPromotion = (newPrice) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PROMOTION_ERROR });
    dispatch({ type: SET_PROMOTION_LOADING });

    const { user } = getState();
    // console.log('user', user);
    const token = getToken(user);

    const { data } = await addPromotionAction(newPrice, token);

    dispatch({ type: ADD_PROMOTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ADD_PROMOTION_FAIL, payload: returnError(error) });
  }
};

export const assignPromotion = (newPrice) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PROMOTION_ERROR });
    dispatch({ type: SET_PROMOTION_LOADING });

    const { user } = getState();
    // console.log('user', user);
    const token = getToken(user);

    const { data } = await assignPromotionAction(newPrice, token);

    dispatch({ type: ASSIGN_PROMOTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ASSIGN_PROMOTION_FAIL, payload: returnError(error) });
  }
};

export const updatePromotion = (updateData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PROMOTION_ERROR });
    dispatch({ type: SET_PROMOTION_LOADING });

    const { user } = getState();
    // console.log('user', user);
    const token = getToken(user);

    const { data } = await editPromotionAction(updateData, token);

    dispatch({ type: UPDATE_PROMOTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_PROMOTION_FAIL, payload: returnError(error) });
  }
};

export const deletePromotion = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PROMOTION_ERROR });
    dispatch({ type: SET_PROMOTION_LOADING });

    const { user } = getState();
    // console.log('user', user);
    const token = getToken(user);

    const { data } = await deletePromotionAction(id, token);

    dispatch({ type: DELETE_PROMOTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: DELETE_PROMOTION_FAIL, payload: returnError(error) });
  }
};

const initialState = {
  language: 'en',
  loading: false,
  promotionLists: [],
  promotion: null,
};

export const promotionSettingReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_PROMOTION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_PROMOTION_ERROR:
      return {
        ...state,
        error: null,
      };

    case GET_PROMOTION_LIST_SUCCESS:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        promotionLists: action.payload.data,
        loading: false,
        error: null,
      };
    case ADD_PROMOTION_SUCCESS:
      return {
        ...state,
        promotion: action.payload.data,
        loading: false,
        error: null,
        success: true,
      };
    case ASSIGN_PROMOTION_SUCCESS:
      return {
        ...state,
        assigned: action.payload.data,
        loading: false,
        error: null,
        success: true,
      };
    case UPDATE_PROMOTION_SUCCESS:
      return {
        ...state,
        promotionUpdated: action.payload.data,
        loading: false,
        error: null,
        success: true,
      };
    case DELETE_PROMOTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        removed: true,
      };
    case LOGOUT_SUCCESS:
      return initialState;

    case GET_PROMOTION_LIST_FAIL:
    case ASSIGN_PROMOTION_FAIL:
    case UPDATE_PROMOTION_FAIL:
    case DELETE_PROMOTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
