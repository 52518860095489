import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'antd/dist/antd.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { filterReducer, userReducer } from './reducer/userReducer';
import { remoteReducer } from './reducer/remoteReducer';
import { outletReducer } from './reducer/outletReducer';
import { reportReducer } from './reducer/reportsReducer';
import { salesReducer } from './reducer/salesData';
import { machineLogsReducer } from './reducer/machineLogReducer';
import { priceSettingReducer } from './reducer/priceSettingReducer';
import { priceDropSettingReducer } from './reducer/dropServiceReducer';
import { reviewDashReducer } from './reducer/reviewDashReducer';
import { sumDashReducer, summaryAnnualReducer } from './reducer/sumDashReducer';
import { utilizationReducer } from './reducer/machineUtilReducer';
import themeReducer from './reducer/themeReducer';
import ThemeController from './theme/themeController';
import { roleReducer } from './reducer/roleReducer';
import { adminReducer } from './reducer/adminReducer';
import { promotionSettingReducer } from './reducer/promotionReducer';
import { utilityReducer } from './reducer/utilityReducer';
import { userManagementRoleReducer } from './reducer/userManagementRoleReducer';
import {
  coinCollectorReducer,
  machineCutOffReducer,
} from './reducer/coinCollectorReducer';
import { coinCollectingReportReducer } from './reducer/coinCollectingReportReducer';

const store = configureStore({
  reducer: {
    user: userReducer,
    outlets: outletReducer,
    remote: remoteReducer,
    reports: reportReducer,
    sales: salesReducer,
    machineLogs: machineLogsReducer,
    pricing: priceSettingReducer,
    dropPricing: priceDropSettingReducer,
    reviewR: reviewDashReducer,
    dashboard: sumDashReducer,
    util: utilizationReducer,
    summaryAnnual: summaryAnnualReducer,
    theme: themeReducer,
    filterReducer: filterReducer,
    roleReducer: roleReducer,
    adminReducer: adminReducer,
    promotion: promotionSettingReducer,
    utilReducer: utilityReducer,
    userRoleReducer: userManagementRoleReducer,
    coinCollector: coinCollectorReducer,
    machineCutOff: machineCutOffReducer,
    coinReport: coinCollectingReportReducer,
  },
});

// console.log(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <Provider store={store}>
      <ThemeController>
        <App />
      </ThemeController>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
