import React, { useEffect, useState } from 'react';
import {
	DashboardOutlined,
	DollarCircleOutlined,
	PoweroffOutlined,
	RetweetOutlined,
	ExperimentOutlined,
	CopyOutlined,
	SnippetsOutlined,
	FlagOutlined,
	UsergroupAddOutlined,
	MessageOutlined,
	MoneyCollectOutlined,
	RedEnvelopeOutlined,
	AccountBookOutlined,
} from '@ant-design/icons';

import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu, Drawer, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilter, loyaltyAdminCheck } from '../reducer/userReducer';
import { useTranslation } from 'react-i18next';
const { Sider } = Layout;

const SideBarComponent = ({ collapsed, setCollapsed, showDrawer, setShowDrawer, devSideBars }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const lang = localStorage.getItem('laundro-language');

	const currentPath = window.location.pathname;

	const { loyaltyToken, error, operator, staff, distributor } = useSelector((state) => state.user);

	const stateData = location.state;

	// console.log('staff', staff);
	function getItem(label, key, icon, children) {
		return {
			label,
			key,
			icon,
			children,
		};
	}

	const octopusUrl = [
		getItem('Transactions Record', '/transactions_record', null, null),
		getItem('Sales Summary', '/sales_summary', null, null),
		getItem('Epayment Record', '/epayment_record', null, null),
		getItem('Manual Pay Record', '/manualPay_record', null, null),
		getItem('Bill Record', '/bill_record', null, null),
		getItem('Error Code Record', '/errorcode_record', null, null),
		getItem('Machines Sales Transactions', '/sales_transaction_record', null, null),
		getItem('Hourly Sales Transactions', '/hourly_sales_transaction', null, null),
		getItem('Sales by Machine Type', '/salesByMachineType', null, null),
		getItem('System Error Report', '/systemErrorReport', null, null),
	];

	const cleanProUrl = [
		getItem('Transactions Record', '/transactions_record', null, null),
		getItem('Sales Summary', '/sales_summary', null, null),
		getItem('Epayment Record', '/epayment_record', null, null),
		getItem('Manual Pay Record', '/manualPay_record', null, null),
		getItem('Bill Record', '/bill_record', null, null),
		getItem('Machines Sales Transactions', '/sales_transaction_record', null, null),
		getItem('Hourly Sales Transactions', '/hourly_sales_transaction', null, null),
		getItem('Sales by Machine Type', '/salesByMachineType', null, null),
		getItem('System Error Report', '/systemErrorReport', null, null),
	];

	const [items, setItems] = useState([
		getItem('Dashboard', '/mainDashboard', <DashboardOutlined />, null),
		getItem('Sales Performance', `/annualOverview`, <DollarCircleOutlined />, null),
		getItem('Machine Utilization', '/machineUtilization', <RetweetOutlined />, null),

		getItem('Remote Activation', '/remoteActivation', <PoweroffOutlined />, null),
		getItem('Utility Monitoring', '/utilityMonitoring', <ExperimentOutlined />),
		getItem(
			'Browsable Report',
			'sub6',
			<CopyOutlined />,
			window.location.href.includes('octopus') ? octopusUrl : cleanProUrl
		),
		getItem('Others', 'sub7', <SnippetsOutlined />, [
			getItem('Price Setting', '/priceList', null, null),
			getItem('Promotion Setting', '/promotion', null, null),
			getItem('Drop Service', '/droppriceList', null, null),
		]),
		getItem('Review Dashboard', `/reviewDashboard`, <FlagOutlined />, null),
		getItem('Loyalty Admin', '/redirectLoyaltyAdmin', <UsergroupAddOutlined />, null),
		getItem('Machine Logging', '/machineLog', <MessageOutlined />, null),
		getItem('Collection', '/coin/collection', <MoneyCollectOutlined />, null),
		getItem('Coin Collection Report', 'sub8', <RedEnvelopeOutlined />, [
			getItem('Coin Collection By Outlet', '/coin/collection/outlet', null, null),
			getItem('Coin Collection By Machine', '/coin/collection/machine', null, null),
			getItem('Bill Collection Report', '/collection/bill', null, null),
		]),
		getItem('Inventory Management', '/inventory/management', <AccountBookOutlined />, null),
	]);

	const redirectToLoyaltyAdmin = () => {
		dispatch(loyaltyAdminCheck());
	};

	useEffect(() => {
		if (error) {
			message.error(error.en);
		}
	}, [error]);

	useEffect(() => {
		if (loyaltyToken) {
			const redirectUrl = `${loyaltyToken}&redirectUrl=${window.location.href}&userType=${stateData.userType}&language=${lang}`;
			window.location.href = redirectUrl;
		}
	}, [loyaltyToken]);

	useEffect(() => {
		if (operator || distributor || staff) {
			const getUser = () => {
				if (operator) {
					return operator;
				} else if (staff) {
					return staff;
				} else return distributor;
			};

			if (getUser().permissions && getUser().permissions.length) {
				const availablePermissions = getUser()
					.permissions.filter((permission) => permission.type === 'laundro' && permission.permission)
					.map((permission) => permission.title);

				let newItems = [];

				for (let i = 0; i < items.length; i++) {
					const { label, children } = items[i];
					if (children && children.length) {
						let newChildren = [];
						for (let j = 0; j < children.length; j++) {
							if (availablePermissions.includes(children[j].label)) newChildren.push(children[j]);
						}
						if (newChildren.length)
							newItems.push({
								...items[i],
								children: newChildren,
							});
					} else {
						if (availablePermissions.includes(label)) newItems.push(items[i]);
					}
				}

				setItems(newItems);
			}
		}
	}, [operator, distributor, staff]);

	// console.log(items)

	// { state: { userType: 'operator' } }

	// console.log('loyaltyToken', loyaltyToken);
	// console.log('stateData', stateData);
	return (
		<Sider width='250' className='sidebar-sider' trigger={null} collapsible collapsed={collapsed}>
			<div className='sidebar-menu-logo' />

			<div className='d-flex flex-column justify-content-between'>
				<div>
					{showDrawer ? (
						<Drawer
							headerStyle={{ height: '300px' }}
							placement='left'
							width={250}
							onClose={() => setShowDrawer(false)}
							open={showDrawer}
						>
							<Menu
								defaultSelectedKeys={[currentPath]}
								theme='light'
								mode='inline'
								items={items.map((e) => {
									return {
										...e,
										label: t(e.label),
									};
								})}
								onClick={(e) => {
									setShowDrawer(false);
									// console.log('e', e.key)
									if (e.key === '/redirectLoyaltyAdmin') {
										return redirectToLoyaltyAdmin();
									} else {
										navigate(e.key, { state: stateData });
										dispatch(clearFilter());
									}
								}}
							/>
						</Drawer>
					) : (
						<Menu
							defaultSelectedKeys={[currentPath]}
							theme='light'
							mode='inline'
							items={items.map((e) => {
								// console.log(e);
								if (e.children) {
									return {
										...e,
										label: t(e.label),
										children: e.children.map((i) => {
											return {
												...i,
												label: t(i.label),
											};
										}),
									};
								}
								return {
									...e,
									label: t(e.label),
								};
							})}
							onClick={(e) => {
								if (e.key === '/redirectLoyaltyAdmin') {
									return redirectToLoyaltyAdmin();
								} else {
									navigate(e.key, { state: stateData });
									dispatch(clearFilter());
								}
							}}
						/>
					)}
				</div>
			</div>
		</Sider>
	);
};

export default SideBarComponent;
