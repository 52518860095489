import { headerConfig, tokenConfig } from './helperFunc';
import axios from 'axios';
const config = headerConfig();

// machines log reducer actions

export const localhost = window.location.href.includes('localhost');

//export const urlPicker = 'https://core.thelaundro.com';

export const urlPicker =
	window.location.href.includes('da.thelaundro') ||
	window.location.href.includes('sandbox.thelaundro') ||
	window.location.href.includes('sb.')
		? 'https://sandbox.thelaundro.com'
		: window.location.href.includes('octopus')
		? 'https://laundro.octopus-laundry.com'
		: 'https://core.thelaundro.com';

export const checkDoPaymentUrl = localhost ? '/get/opid' : `${urlPicker}/get/opid`;

// ********************************* MACHINE LOGS  *********************************
// /api/distributor/v1/operator/fetch
const machineRoot = localhost ? '/api/logging/v1' : `${urlPicker}/api/logging/v1`;
const external = localhost ? '/api/distributor/v1/operator' : `${urlPicker}/api/distributor/v1/operator`;

export const fetchOperatorsAction = (distributorToken) => axios.get(`${external}/fetch`, tokenConfig(distributorToken));

export const machinesLogsAction = (operatorId, token) =>
	axios.get(`${machineRoot}/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const addMachineLogAction = (formData, token) =>
	axios.post(`${machineRoot}/create`, formData, tokenConfig(token));

export const updateMachineLogAction = (formData, token) =>
	axios.put(`${machineRoot}/edit`, formData, tokenConfig(token));

// ********************************* MACHINE utilization  *********************************
const warrantyLink = localhost
	? '/api/maintenance-warranty/v1/machine/warranty'
	: `${urlPicker}/api/maintenance-warranty/v1/machine/warranty`;

export const addWarrantyAction = (formData, token) => axios.post(`${warrantyLink}/add`, formData, tokenConfig(token));

// machineUtilReducer reducer actions
const machineUtilRoot = localhost ? '/api/report/utilization/v1' : `${urlPicker}/api/report/utilization/v1`;

export const annualUtilReportAction = (reqBody, token) =>
	axios.post(`${machineUtilRoot}/annual`, reqBody, tokenConfig(token));

export const monthlyUtilReportAction = (reqBody, token) =>
	axios.post(`${machineUtilRoot}/monthly`, reqBody, tokenConfig(token));

export const outletDailyUtilAction = (year, month, outletId, operatorId, token) =>
	axios.get(
		`${machineUtilRoot}/daily?year=${year}&month=${month}&outletId=${outletId}&operatorId=${operatorId}`,
		tokenConfig(token)
	);

export const machineUtilTimeAnnualAction = (reqBody, token) =>
	axios.post(`${machineUtilRoot}/runtime`, reqBody, tokenConfig(token));

export const machineUtilTimeMonthAction = (reqBody, token) =>
	axios.post(`${machineUtilRoot}/runtime`, reqBody, tokenConfig(token));

export const annualTempPreferenceAction = (reqBody, token) =>
	axios.post(`${machineUtilRoot}/temperature`, reqBody, tokenConfig(token));

export const monthTempPreferenceAction = (reqBody, token) =>
	axios.post(`${machineUtilRoot}/temperature`, reqBody, tokenConfig(token));

// ********************************* outlet actions  *********************************
// outletReducer reducer actions
const outletRoot = localhost ? '/api/outlet/v1' : `${urlPicker}/api/outlet/v1`;

export const allOutletsAction = (operatorId, token) =>
	axios.get(`${outletRoot}/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const allOutletsDataOnlyAction = (operatorId, token) =>
	axios.get(`${outletRoot}/fetch/outlet_only?operatorId=${operatorId}`, tokenConfig(token));

export const createOutletAction = (reqBody, token) => axios.post(`${outletRoot}/create`, reqBody, tokenConfig(token));
export const updateOutletAction = (reqBody, token) => axios.put(`${outletRoot}/edit`, reqBody, tokenConfig(token));

// ********************************* pricing strategy *********************************
export const updateOutletGroupingAction = (reqBody, token) =>
	axios.patch(`${outletRoot}/machine_grouping/edit`, reqBody, tokenConfig(token));

// priceSettingReducer
const priceRoot = localhost ? '/api/pricing/v1/strategy' : `${urlPicker}/api/pricing/v1/strategy`;

export const getPriceListAction = (operatorId, token) =>
	axios.get(`${priceRoot}/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const addPricingAction = (newPrice, token) => axios.post(`${priceRoot}/add`, newPrice, tokenConfig(token));

export const assignPricingAction = (newPrice, token) => axios.put(`${priceRoot}/assign`, newPrice, tokenConfig(token));

export const editPricingAction = (updateData, token) => axios.put(`${priceRoot}/edit`, updateData, tokenConfig(token));

export const deletePricingAction = (resData, token) => {
	return axios.delete(`${priceRoot}/delete`, {
		...tokenConfig(token),
		data: resData,
	});
};

// ********************************* pricing promotion *********************************
// promotion actions /api/pricing/v1/promotion/
const promotionRoot = localhost ? '/api/pricing/v1/promotion' : `${urlPicker}/api/pricing/v1/promotion`;

export const getPromotionListAction = (operatorId, token) =>
	axios.get(`${promotionRoot}/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const addPromotionAction = (newPrice, token) => axios.post(`${promotionRoot}/add`, newPrice, tokenConfig(token));

export const assignPromotionAction = (newPrice, token) =>
	axios.put(`${promotionRoot}/assign`, newPrice, tokenConfig(token));

export const editPromotionAction = (updateData, token) =>
	axios.put(`${promotionRoot}/edit`, updateData, tokenConfig(token));

export const deletePromotionAction = (id) => {
	return axios.delete(`${promotionRoot}/delete`, {
		...tokenConfig(),
		data: { id },
	});
};

// ********************************* pricing strategy *********************************

// dropServiceReducer
const priceDropRoot = localhost ? '/api/pricing/v1/dropservice' : `${urlPicker}/api/pricing/v1/dropservice`;

export const getDropPriceListAction = (operatorId, token) =>
	axios.get(`${priceDropRoot}/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const addDropPricingAction = (newPrice, token) => axios.post(`${priceDropRoot}/add`, newPrice, tokenConfig(token));

export const assignDropPricingAction = (newPrice, token) => axios.put(`${priceDropRoot}/assign`, newPrice, tokenConfig(token));

export const editDropPricingAction = (updateData, token) => axios.put(`${priceDropRoot}/edit`, updateData, tokenConfig(token));

export const deleteDropPricingAction = (resData, token) => {
	return axios.delete(`${priceDropRoot}/delete`, {
		...tokenConfig(token),
		data: resData,
	});
};

// ********************************* remote machine *********************************
// remoteReducer  reducer actions
const remoteRoot = localhost ? '/api/machine/v1' : `${urlPicker}/api/machine/v1`;

export const remoteMachineAction = (operatorId = '', outletId = '', token, coin = '') =>
	axios.get(`${remoteRoot}/fetch?operatorId=${operatorId}&outletId=${outletId}&coin=${coin}`, tokenConfig(token));

export const remoteActivateAction = (values, token) =>
	axios.post(`${remoteRoot}/remote_activate`, values, tokenConfig(token));

export const machineMonitorAction = (values, token) =>
	axios.put(`${remoteRoot}/monitoring`, values, tokenConfig(token));

export const stopMachineAction = (values, token) => axios.post(`${remoteRoot}/remote_stop`, values, tokenConfig(token));

// universal query
export const typesLookupAction = () => axios.get(`${remoteRoot}/template/lookup`, tokenConfig());

export const allMachinesAction = (operatorId, outletId, coin, token) =>
	axios.get(`${remoteRoot}/fetch?&operatorId=${operatorId}&outletId=${outletId}&coin=${coin}`, tokenConfig(token));

// ********************************* machine reports *********************************
// report reducer actions
const reportRoot = localhost ? '/api/report/sales/v1' : `${urlPicker}/api/report/sales/v1`;
const errorReportRoot = localhost ? '/api/report/errorcode/v1' : `${urlPicker}/api/report/errorcode/v1`;
const reportRootV2 = localhost ? '/api/report/sales/v2' : `${urlPicker}/api/report/sales/v2`;

export const getPaymentTypesAction = (token) => axios.get(`${reportRoot}/epayment/methods`, tokenConfig(token));

export const transReportAction = (values, token) =>
	axios.post(`${reportRoot}/transactions`, values, tokenConfig(token));

export const summaryReportAction = (values, token) =>
	axios.post(`${reportRootV2}/sales_summary`, values, tokenConfig(token));

export const epayReportAction = (values, token) => axios.post(`${reportRoot}/epayment`, values, tokenConfig(token));
export const manualPayReportAction = (values, token) =>
	axios.post(`${reportRoot}/manualpay`, values, tokenConfig(token));

export const errorcodeReportAction = async (values, token) =>
	await axios.post(`${errorReportRoot}/fetch`, values, tokenConfig(token));

export const billReportAction = (values, token) => axios.post(`${reportRoot}/bill`, values, tokenConfig(token));

// ********************************* review feedback *********************************
export const machineSalesTransReportAction = (values, token) =>
	axios.post(`${reportRoot}/machine`, values, tokenConfig(token));

export const hourlySalesTransReportAction = (values, token) =>
	axios.post(`${reportRoot}/sales/hourly`, values, tokenConfig(token));

export const salesByMachineTypeAction = (values, token) =>
	axios.post(`${reportRoot}/machine_type`, values, tokenConfig(token));

// systemErrorReportAction
const errorStatusRoot = localhost ? '/api/report/errorcode/v1' : `${urlPicker}/api/report/errorcode/v1`;
export const systemErrorReportAction = (values, token) =>
	axios.post(`${errorStatusRoot}/alertsystem/fetch`, values, tokenConfig(token));

export const systemErrorReportUpdateAction = (values, token) =>
	axios.put(`${errorStatusRoot}/alertsystem/status/update`, values, tokenConfig(token));

//todo

// reviewDashReducer reducer actions
const reviewsRoot = localhost ? '/api/feedback/v1' : `${urlPicker}/api/feedback/v1`;
export const getReviewsAction = (query, token) => axios.get(`${reviewsRoot}/fetch?${query}`, tokenConfig(token));

export const resolveReviewAction = (reqBody, token) => axios.put(`${reviewsRoot}/resolve`, reqBody, tokenConfig(token));

// ********************************* sales data *********************************
// sales data reducer actions
const salesRoot = localhost ? '/api/report/sales/v1' : `${urlPicker}/api/report/sales/v1`;

export const annualSalesReportAction = (reqBody, token) =>
	axios.post(`${salesRoot}/annual`, reqBody, tokenConfig(token));

export const totalDailySalesByMonthAction = (reqBody, token) =>
	axios.post(`${salesRoot}/monthly`, reqBody, tokenConfig(token));

export const totalOutletSalesByYearAction = (year, operatorId, token) =>
	axios.get(`${salesRoot}/type/outlet?year=${year}&operatorId=${operatorId}`, tokenConfig(token));

export const todaySalesAction = (operatorId, token) =>
	axios.get(`${salesRoot}/today/outlets?operatorId=${operatorId}`, tokenConfig(token));

export const totalOutletSalesMonthAction = (year, month, operatorId, token) =>
	axios.get(`${salesRoot}/type/outlet?year=${year}&month=${month}&operatorId=${operatorId}`, tokenConfig(token));

export const annualBillReportAction = (year, operatorId, token) =>
	axios.post(`${salesRoot}/bill/outlet`, { year, operatorId }, tokenConfig(token));

export const billReportMonthAction = (year, month, operatorId, token) =>
	axios.post(`${salesRoot}/bill/outlet`, { year, operatorId, month }, tokenConfig(token));

export const dailySalesReportAction = (date, operatorId, token) =>
	axios.get(`${salesRoot}/type/outlet?date=${date}&operatorId=${operatorId}`, tokenConfig(token));

export const dailySalesWithUtilAction = (date, operatorId, outletSingleId, token) =>
	axios.post(`${salesRoot}/daily/machine`, { date, operatorId, outletId: outletSingleId }, tokenConfig(token));

//pie , first api for sales performance
export const annualTypeDistributionAction = (reqBody, token) =>
	axios.post(`${salesRoot}/distribution/machine_type`, reqBody, tokenConfig(token));

//pie , second api for sales performance
export const annualWeightDistributionAction = (reqBody, token) =>
	axios.post(`${salesRoot}/distribution/machine_weight`, reqBody, tokenConfig(token));

//pie , third api for sales performance
export const monthTypeDistributionAction = (reqBody, token) =>
	axios.post(`${salesRoot}/distribution/machine_type`, reqBody, tokenConfig(token));

//pie , fourth api for sales performance
export const monthWeightDistributionAction = (reqBody, token) =>
	axios.post(`${salesRoot}/distribution/machine_weight`, reqBody, tokenConfig(token));

// sum dash reducer actions
export const channelSalesAction = (year, operatorId, token) =>
	axios.get(`${salesRoot}/type/channel?year=${year}&operatorId=${operatorId}`, tokenConfig(token));

export const machineSalesAction = (year, operatorId, token) =>
	axios.get(`${salesRoot}/type/machine?year=${year}&operatorId=${operatorId}`, tokenConfig(token));

export const weeklySalesAction = (year, operatorId, token) =>
	axios.get(`${salesRoot}/weekly?year=${year}&operatorId=${operatorId}`, tokenConfig(token));

const sumDRoot = localhost ? '/api/machine/v1' : `${urlPicker}/api/machine/v1`;

// console.log('tokenConfig()', tokenConfig())
export const machineStatusAction = (year, operatorId, token) => {
	return axios.get(`${sumDRoot}/status?year=${year}&operatorId=${operatorId}`, tokenConfig(token));
};

// ********************************* user reducer *********************************
// user reducer actions

const userRoot = localhost ? '/api/auth/v1' : `${urlPicker}/api/auth/v1`;

export const resetPasswordAction = (updatedUser) => axios.put(`${userRoot}/password/reset`, updatedUser);

export const sendResetEmailAction = (body) =>
	axios.get(
		`${userRoot}/password/forget?email=${body.email}&brand=${body.brand}&role=${body.role}&domain=${body.domain}`
	);

export const loginStaffAction = (loginUser) => axios.post(`${userRoot}/staff/login`, loginUser, config);

export const registerStaffAction = (newUser) => axios.post(`${userRoot}/staff/register`, newUser, config);

export const registerOperatorAction = (newUser) => axios.post(`${userRoot}/operator/register`, newUser, config);

export const verifyOperatorAction = (token) => axios.post(`${userRoot}/verify/account`, { token }, config);

export const loginOperatorAction = (loginUser) => axios.post(`${userRoot}/operator/login`, loginUser, config);

export const checkSessionAction = (token) => axios.post(`${userRoot}/session`, { token }, config);

export const changePasswordAction = (values) => axios.put(`${userRoot}/password/reset`, values, config);

export const loyaltyAdminCheckAction = (token) => axios.get(`${userRoot}/loyalty/login`, tokenConfig(token));

export const loginDistributorAction = (values) => axios.post(`${userRoot}/distributor/login`, values, config);

// Admin routes
export const loginAdminAction = (loginUser) => axios.post(`${userRoot}/admin/login`, loginUser, config);

// ********************************* Revenue Monster *********************************
const userManagementRoot = localhost ? '/api/management/v1' : `${urlPicker}/api/management/v1`;

export const addNewUserAction = (newUser, token) =>
	axios.post(`${userManagementRoot}/user/create`, newUser, tokenConfig(token));

export const updateUserAction = (updateData, token) =>
	axios.put(`${userManagementRoot}/user/edit`, updateData, tokenConfig(token));

export const updateOutletVisibilityAction = (updateData, token) =>
	axios.put(`${userManagementRoot}/controller/outlet_controller`, updateData, tokenConfig(token));

export const updateOperatorVisibilityAction = (updateData, token) =>
	axios.put(`${userManagementRoot}/controller/operator_controller`, updateData, tokenConfig(token));

//

export const getUsersListAction = (token) => axios.get(`${userManagementRoot}/user/fetch`, tokenConfig(token));

export const getUserRoleListAction = (token) => axios.get(`${userManagementRoot}/role/fetch`, tokenConfig(token));

export const getUserPermissionsAction = (token) =>
	axios.get(`${userManagementRoot}/permissions/fetch`, tokenConfig(token));

export const addUserRoleAction = (newRole, token) =>
	axios.post(`${userManagementRoot}/role/create`, newRole, tokenConfig(token));

export const assignUserRoleAction = (newRole, token) =>
	axios.put(`${userManagementRoot}/role/assign`, newRole, tokenConfig(token));

export const editUserRoleAction = (updateData, token) =>
	axios.put(`${userManagementRoot}/role/edit`, updateData, tokenConfig(token));

export const deleteUserRoleAction = (id) => {
	return axios.delete(`${userManagementRoot}/delete`, {
		...tokenConfig(),
		data: { id },
	});
};

// monster action urls
const rmRoot = localhost ? '/api/rm/v1' : `${urlPicker}/api/rm/v1`;

export const createRMAction = (RmDetails, token) => axios.post(`${rmRoot}/create`, RmDetails, tokenConfig(token));

export const fetchRMAction = (operatorId, token) =>
	axios.get(`${rmRoot}/fetch?operatorId=${operatorId}`, tokenConfig(token));

// ********************************* admin role actions *********************************
// role list && admin routes action urls
const adminRoot = localhost ? '/api/management/v1' : `${urlPicker}/api/management/v1`;
export const getRoleListAction = (token) => axios.get(`${adminRoot}/role/fetch`, tokenConfig(token));

export const getPermissionsAction = (token) => axios.get(`${adminRoot}/permissions/fetch`, tokenConfig(token));

export const addRoleAction = (newRole, token) => axios.post(`${adminRoot}/role/create`, newRole, tokenConfig(token));

export const assignRoleAction = (newRole, token) => axios.put(`${adminRoot}/role/assign`, newRole, tokenConfig(token));
//todo
export const editRoleAction = (updateData, token) =>
	axios.put(`${adminRoot}/role/edit`, updateData, tokenConfig(token));
//todo
export const deleteRoleAction = (id) => {
	return axios.delete(`${adminRoot}/delete`, {
		...tokenConfig(),
		data: { id },
	});
};

//

export const addUserAction = (reqBody, token) => axios.post(`${adminRoot}/user/create`, reqBody, tokenConfig(token));

export const addClientAction = (reqBody, token) =>
	axios.post(`${adminRoot}/client/create`, reqBody, tokenConfig(token));

export const getClientsAction = (token) => axios.get(`${adminRoot}/client/fetch`, tokenConfig(token));

export const getDistributorsAction = (token) => axios.get(`${adminRoot}/distributor/fetch`, tokenConfig(token));

export const getUsersAction = (token, role) =>
	axios.get(`${adminRoot}/user/fetch${role ? `?userType=${role}` : ''}`, tokenConfig(token));

export const assignOperatorAction = (reqBody, token) =>
	axios.put(`${adminRoot}/operator/assign`, reqBody, tokenConfig(token));

export const assignClientOperatorAction = (reqBody, token) =>
	axios.put(`${adminRoot}/operator/assign_client`, reqBody, tokenConfig(token));

export const addDistributorAction = (reqBody, token) =>
	axios.post(`${adminRoot}/distributor/create`, reqBody, tokenConfig(token));

// ********************************* Utility actions *********************************
// api/utility/v1/reset_volume
const utilityRoot = localhost ? '/api/utility/v1' : `${urlPicker}/api/utility/v1`;

export const getAllGasSensorsAction = (operatorId, outletSingleId, token) =>
	axios.get(`${utilityRoot}/fetch_currentdata?operatorId=${operatorId}&outletId=${outletSingleId}`, tokenConfig(token));

export const getRecordsOfUtilityAction = (reqBody, token) =>
	axios.post(`${utilityRoot}/fetch_records`, reqBody, tokenConfig(token));

export const resetVolumeAction = (reqBody, token) =>
	axios.put(`${utilityRoot}/reset_volume`, reqBody, tokenConfig(token));

export const refillVolumeAction = (reqBody, token) =>
	axios.put(`${utilityRoot}/refill_volume`, reqBody, tokenConfig(token));

export const fetchOutletsControllerAction = async (data, token) =>
	await axios.post(`${adminRoot}/controller/fetch`, data, tokenConfig(token));

export const fetchController = async (data, token) =>
	await axios.post(`${adminRoot}/controller/fetch`, data, tokenConfig(token));

// get outlet pinger status
const outletOnlineRoot = localhost ? '/api/status/v1' : `${urlPicker}/api/status/v1`;
export const allOutletsOnlineStatusAction = (reqBody, token) => {
	const { outletId, startDate, endDate, operatorId } = reqBody;

	const queryParams =
		startDate && endDate
			? `outletId=${outletId}&startDate=${startDate}&endDate=${endDate}&operatorId=${operatorId}`
			: `outletId=${outletId}&operatorId=${operatorId}`;
	return axios.get(`${outletOnlineRoot}/pingerlog/fetch?${queryParams}`, tokenConfig(token));
};

// coinCollection Reducer
const coinCollectionRoot = localhost ? '/api/audit/v1/coin_collection' : `${urlPicker}/api/audit/v1/coin_collection`;
const billCollectionRoot = localhost ? '/api/audit/v1/bill_collection' : `${urlPicker}/api/audit/v1/bill_collection`;
export const machineCutoffStartAction = (data, token) =>
	axios.post(`${coinCollectionRoot}/start`, data, tokenConfig(token));

export const machineCutoffAction = (data, token) =>
	axios.post(`${coinCollectionRoot}/cutoff`, data, tokenConfig(token));

export const machineCutoffSubmitAction = (data, token) =>
	axios.put(`${coinCollectionRoot}/submit`, data, tokenConfig(token));

export const machineCutoffBillSubmitAction = (data, token) =>
	axios.put(`${billCollectionRoot}/submit`, data, tokenConfig(token));

export const machineCutoffListAction = (operatorId, token) =>
	axios.get(`${coinCollectionRoot}/dashboard?operatorId=${operatorId}`, tokenConfig(token));

export const getMachineCutoffHistoryAction = (data, token) =>
	axios.get(
		`${coinCollectionRoot}/cutoff/details?coinCollectionId=${data.coinCollectionId}&operatorId=${data.operatorId}`,
		tokenConfig(token)
	);

export const getCutoffStatusAction = (data, token) =>
	axios.get(
		`${coinCollectionRoot}/cutoff/status?id=${data.id}&outletId=${data.outletId}&operatorId=${data.operatorId}`,
		tokenConfig(token)
	);

// report coin collection
export const getCoinCollectionByOutletAction = (reqBody, token) =>
	axios.post(`${coinCollectionRoot}/report/outlet`, reqBody, tokenConfig(token));

export const getCoinCollectionByMachineAction = (reqBody, token) =>
	axios.post(`${coinCollectionRoot}/report/machine`, reqBody, tokenConfig(token));

export const getBillCollectionReportAction = (reqBody, token) =>
	axios.post(`${billCollectionRoot}/report`, reqBody, tokenConfig(token));

const coinInventory = localhost ? '/api/inventory/v1' : `${urlPicker}/api/inventory/v1`;

export const refillCoinInventoryAction = (reqBody, token) =>
	axios.put(`${coinInventory}/refill_coin`, reqBody, tokenConfig(token));

export const resetCoinInventoryAction = (reqBody, token) =>
	axios.put(`${coinInventory}/reset_coin`, reqBody, tokenConfig(token));

// export const addCoinCollectionAction = (newCoinGroup, token) =>
// 	axios.post(`${remoteRoot}/group/create`, newCoinGroup, tokenConfig(token));

// export const assignCoinCollectionAction = (coinGroup, token) =>
// 	axios.put(`${remoteRoot}/group/assign`, coinGroup, tokenConfig(token));

// export const editCoinCollectionAction = (updateData, token) =>
// 	axios.put(`${remoteRoot}/group/edit`, updateData, tokenConfig(token));

// export const deleteCoinCollectionAction = (id) => {
// 	return axios.delete(`${remoteRoot}/delete`, { ...tokenConfig(), data: { id } });
// };

export const getCoinCollectionAction = (outletId, token) =>
	axios.get(`${remoteRoot}/group/fetch?outletId=${outletId}`, tokenConfig(token));

export const addCoinCollectionAction = (newCoinGroup, token) =>
	axios.post(`${remoteRoot}/group/create`, newCoinGroup, tokenConfig(token));

export const assignCoinCollectionAction = (coinGroup, token) =>
	axios.put(`${remoteRoot}/group/assign`, coinGroup, tokenConfig(token));

export const editCoinCollectionAction = (updateData, token) =>
	axios.put(`${remoteRoot}/group/edit`, updateData, tokenConfig(token));

export const deleteCoinCollectionAction = (id) => {
	return axios.delete(`${remoteRoot}/delete`, {
		...tokenConfig(),
		data: { id },
	});
};
