import { getToken, returnError } from './helperFunc';
import {
	getCoinCollectionAction,
	addCoinCollectionAction,
	assignCoinCollectionAction,
	editCoinCollectionAction,
	deleteCoinCollectionAction,
	//
	machineCutoffStartAction,
	machineCutoffAction,
	machineCutoffSubmitAction,
	machineCutoffListAction,
	getMachineCutoffHistoryAction,
	getCutoffStatusAction,
	machineCutoffBillSubmitAction,
} from './requestEndpoints';

import { LOGOUT_SUCCESS } from './userReducer';

const SET_LOADING_COIN = 'SET_LOADING_COIN';
const CLEAR_ERROR_COIN = 'CLEAR_ERROR_COIN';

const ADD_COIN_COLLECTION_SUCCESS = 'ADD_COIN_COLLECTION_SUCCESS';
const ADD_COIN_COLLECTION_FAIL = 'ADD_COIN_COLLECTION_FAIL';

const ASSIGN_COIN_COLLECTION_SUCCESS = 'ASSIGN_COIN_COLLECTION_SUCCESS';
const ASSIGN_COIN_COLLECTION_FAIL = 'ASSIGN_COIN_COLLECTION_FAIL';

const DELETE_COIN_COLLECTION_SUCCESS = 'DELETE_COIN_COLLECTION_SUCCESS';
const DELETE_COIN_COLLECTION_FAIL = 'DELETE_COIN_COLLECTION_FAIL';

const UPDATE_COIN_COLLECTION_SUCCESS = 'UPDATE_COIN_COLLECTION_SUCCESS';
const UPDATE_COIN_COLLECTION_FAIL = 'UPDATE_COIN_COLLECTION_FAIL';

const GET_COIN_COLLECTIONS_SUCCESS = 'GET_COIN_COLLECTIONS_SUCCESS';
const GET_COIN_COLLECTIONS_FAIL = 'GET_COIN_COLLECTIONS_FAIL';

// coin_collection/dashboard

// FOR PRICE SETTING

export const getCoinCollectionList = (outletId) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_COIN });
		dispatch({ type: SET_LOADING_COIN });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getCoinCollectionAction(outletId, token);

		dispatch({ type: GET_COIN_COLLECTIONS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: GET_COIN_COLLECTIONS_FAIL,
			payload: returnError(error),
		});
	}
};

export const addCoinCollection = (newPrice) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_COIN });
		dispatch({ type: SET_LOADING_COIN });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await addCoinCollectionAction(newPrice, token);

		dispatch({ type: ADD_COIN_COLLECTION_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: ADD_COIN_COLLECTION_FAIL, payload: returnError(error) });
	}
};

export const assignCoinCollection = (newPrice) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_COIN });
		dispatch({ type: SET_LOADING_COIN });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await assignCoinCollectionAction(newPrice, token);

		dispatch({ type: ASSIGN_COIN_COLLECTION_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: ASSIGN_COIN_COLLECTION_FAIL,
			payload: returnError(error),
		});
	}
};

export const updateCoinCollection = (updateData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_COIN });
		dispatch({ type: SET_LOADING_COIN });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await editCoinCollectionAction(updateData, token);

		dispatch({ type: UPDATE_COIN_COLLECTION_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: UPDATE_COIN_COLLECTION_FAIL,
			payload: returnError(error),
		});
	}
};

export const deleteCoinCollection = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_COIN });
		dispatch({ type: SET_LOADING_COIN });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await deleteCoinCollectionAction(id, token);

		dispatch({ type: DELETE_COIN_COLLECTION_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: DELETE_COIN_COLLECTION_FAIL,
			payload: returnError(error),
		});
	}
};

const initialState = {
	language: 'en',
	loading: false,
	coinCollections: [],
};

export const coinCollectorReducer = function (state = initialState, action) {
	switch (action.type) {
		// this is for fetching loading time setter
		case SET_LOADING_COIN:
			return {
				...state,
				loading: true,
			};

		case CLEAR_ERROR_COIN:
			return {
				...state,
				error: null,
				loading: false,
			};

		case GET_COIN_COLLECTIONS_SUCCESS:
			// console.log('action.payload', action.payload);
			return {
				...state,
				coinCollections: action.payload.data,
				loading: false,
				error: null,
			};
		case ADD_COIN_COLLECTION_SUCCESS:
			return {
				...state,
				coinCollection: action.payload.data,
				loading: false,
				error: null,
			};
		case ASSIGN_COIN_COLLECTION_SUCCESS:
			return {
				...state,
				assigned: action.payload.data,
				loading: false,
				error: null,
			};
		case UPDATE_COIN_COLLECTION_SUCCESS:
			return {
				...state,
				coinCollectionUpdated: action.payload.data,
				loading: false,
				error: null,
				success: true,
			};
		case DELETE_COIN_COLLECTION_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				removed: true,
			};
		case LOGOUT_SUCCESS:
			return initialState;

		case ASSIGN_COIN_COLLECTION_FAIL:
		case UPDATE_COIN_COLLECTION_FAIL:
		case DELETE_COIN_COLLECTION_FAIL:
		case GET_COIN_COLLECTIONS_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		default:
			return state;
	}
};

const SET_LOADING_CUTOFF = 'SET_LOADING_CUTOFF';
const CLEAR_ERROR_CUTOFF = 'CLEAR_ERROR_CUTOFF';

const CUTOFF_START_SUCCESS = 'CUTOFF_START_SUCCESS';
const CUTOFF_START_FAIL = 'CUTOFF_START_FAIL';

const CUTOFF_SUCCESS = 'CUTOFF_SUCCESS';
const CUTOFF_FAIL = 'CUTOFF_FAIL';

const CUTOFF_SUBMIT_SUCCESS = 'CUTOFF_SUBMIT_SUCCESS';
const CUTOFF_SUBMIT_FAIL = 'CUTOFF_SUBMIT_FAIL';

const GET_CUTOFF_LIST_SUCCESS = 'GET_CUTOFF_LIST_SUCCESS';
const GET_CUTOFF_LIST_FAIL = 'GET_CUTOFF_LIST_FAIL';

const GET_CUTOFF_STATUS_SUCCESS = 'GET_CUTOFF_STATUS_SUCCESS';
const GET_CUTOFF_STATUS_FAIL = 'GET_CUTOFF_STATUS_FAIL';

const GET_CUTOFF_HISTORY_SUCCESS = 'GET_CUTOFF_HISTORY_SUCCESS';
const GET_CUTOFF_HISTORY_FAIL = 'GET_CUTOFF_HISTORY_FAIL';

export const machineCutoffStart = (dataBody) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_CUTOFF });
		dispatch({ type: SET_LOADING_CUTOFF });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await machineCutoffStartAction(dataBody, token);

		dispatch({ type: CUTOFF_START_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: CUTOFF_START_FAIL, payload: returnError(error) });
	}
};

export const machineCutoff = (dataBody) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_CUTOFF });
		dispatch({ type: SET_LOADING_CUTOFF });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await machineCutoffAction(dataBody, token);

		dispatch({ type: CUTOFF_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: CUTOFF_FAIL, payload: returnError(error) });
	}
};

export const machineCutoffSubmit = (dataBody) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_CUTOFF });
		dispatch({ type: SET_LOADING_CUTOFF });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await machineCutoffSubmitAction(dataBody, token);

		dispatch({ type: CUTOFF_SUBMIT_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: CUTOFF_SUBMIT_FAIL, payload: returnError(error) });
	}
};

export const machineBillCutoffSubmit = (dataBody) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_CUTOFF });
		dispatch({ type: SET_LOADING_CUTOFF });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await machineCutoffBillSubmitAction(dataBody, token);

		dispatch({ type: CUTOFF_SUBMIT_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: CUTOFF_SUBMIT_FAIL, payload: returnError(error) });
	}
};

export const machineCutoffList = (dataBody) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_CUTOFF });
		dispatch({ type: SET_LOADING_CUTOFF });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await machineCutoffListAction(dataBody, token);

		dispatch({ type: GET_CUTOFF_LIST_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: GET_CUTOFF_LIST_FAIL, payload: returnError(error) });
	}
};

export const getMachineCutoffHistory = (dataBody) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_CUTOFF });
		dispatch({ type: SET_LOADING_CUTOFF });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getMachineCutoffHistoryAction(dataBody, token);

		dispatch({ type: GET_CUTOFF_HISTORY_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: GET_CUTOFF_HISTORY_FAIL, payload: returnError(error) });
	}
};

export const getMachineCutoffStatus = (dataBody) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_CUTOFF });
		dispatch({ type: SET_LOADING_CUTOFF });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getCutoffStatusAction(dataBody, token);

		dispatch({ type: GET_CUTOFF_STATUS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: GET_CUTOFF_STATUS_FAIL, payload: returnError(error) });
	}
};

const initialState2 = {
	language: 'en',
	loading: false,
	machineCutoff: '',
	machineCutoffLists: [],
	error: null,
	coinCollectionCompleted: null,
};

export const machineCutOffReducer = function (state = initialState2, action) {
	switch (action.type) {
		// this is for fetching loading time setter
		case SET_LOADING_CUTOFF:
			return {
				...state,
				loading: true,
			};

		case CLEAR_ERROR_CUTOFF:
			return {
				...state,
				error: null,
				coinCollectionCompleted: null,
				loading: false,
				cutoffStart: null,
			};

		case CUTOFF_START_SUCCESS:
			// console.log('action.payload', action.payload);
			return {
				...state,
				cutoffStart: action.payload.data,
				loading: false,
				error: null,
			};
		case CUTOFF_SUCCESS:
			return {
				...state,
				cutoffSuccess: action.payload.data,
				loading: false,
				error: null,
			};
		case CUTOFF_SUBMIT_SUCCESS:
			// console.log('action.payload', action.payload);
			return {
				...state,
				cutoffSubmit: action.payload.data,
				cutoffAmountData: action.payload.cutoffData,
				loading: false,
				error: null,
			};
		case GET_CUTOFF_LIST_SUCCESS:
			return {
				...state,
				machineCutoffLists: action.payload.data,
				loading: false,
				error: null,
			};
		case GET_CUTOFF_HISTORY_SUCCESS:
			// console.log('action.payload', action.payload);
			return {
				...state,
				machineCutoffHistory: action.payload.data,
				cutoffData: action.payload.cutoffData,
				loading: false,
				error: null,
			};
		case GET_CUTOFF_STATUS_SUCCESS:
			return {
				...state,
				cutoffAmountData: null,
				machineCutoffStatus: action.payload.data,
				coinCollectionCompleted: action.payload.coinCollectionCompleted,
				vendingData: action.payload.vending,
				loading: false,
				error: null,
			};
		case LOGOUT_SUCCESS:
			return initialState2;

		case CUTOFF_START_FAIL:
		case CUTOFF_FAIL:
		case CUTOFF_SUBMIT_FAIL:
		case GET_CUTOFF_LIST_FAIL:
		case GET_CUTOFF_HISTORY_FAIL:
			return {
				...state,
				cutoffSubmit: null,
				cutoffAmountData: null,
				loading: false,
				error: action.payload,
				coinCollectionCompleted: null,
			};

		default:
			return state;
	}
};
