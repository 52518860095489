import { getToken, returnError } from './helperFunc';
import { getReviewsAction, resolveReviewAction } from './requestEndpoints';

import { LOGOUT_SUCCESS } from './userReducer';

const SET_REVIEW_LOADING = 'SET_REVIEW_LOADING';
const CLEAR_REVIEW_ERROR = 'CLEAR_REVIEW_ERROR';

const RESOLVE_REVIEW_SUCCESS = 'RESOLVE_REVIEW_SUCCESS';
const RESOLVE_REVIEW_FAIL = 'RESOLVE_REVIEW_FAIL';

const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL';

// FOR OUTLETS

export const getReviews = (query) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_REVIEW_LOADING });
    dispatch({ type: CLEAR_REVIEW_ERROR });

    const { user } = getState();
    // console.log('user', user);
    const token = getToken(user);

    const { data } = await getReviewsAction(query, token);

    dispatch({ type: GET_REVIEWS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_REVIEWS_FAIL, payload: returnError(error) });
  }
};

export const resolveReview = (reqBody) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_REVIEW_LOADING });
    dispatch({ type: CLEAR_REVIEW_ERROR });

    const { user } = getState();
    // console.log('user', user);
    const token = getToken(user);

    const { data } = await resolveReviewAction(reqBody, token);

    dispatch({ type: RESOLVE_REVIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: RESOLVE_REVIEW_FAIL, payload: returnError(error) });
  }
};

const initialState = {
  language: 'en',
  error: null,
  loading: false,
  reviews: [],
  review: null,
};

export const reviewDashReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_REVIEW_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_REVIEW_ERROR:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case GET_REVIEWS_SUCCESS:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        reviews: action.payload,
        loading: false,
        error: null,
      };
    case RESOLVE_REVIEW_SUCCESS:
      return {
        ...state,
        review: action.payload.data,
        error: null,
      };

    case LOGOUT_SUCCESS:
      return initialState;

    case GET_REVIEWS_FAIL:
    case RESOLVE_REVIEW_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
