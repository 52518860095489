import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
	// debug: true,
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
	resources: {
		en: {
			translation: {
				'Summary Dashboard': 'Summary Dashboard',
				Dashboard: 'Dashboard',
				'Outlets Online': 'Outlets Online',
				'Outlets Offline': 'Outlets Offline',
				'Machines Running': 'Machines Running',
				'Machines Idle': 'Machines Idle',
				'Machines Offline': 'Machines Offline',
				'Sales by Channels': 'Sales by Channels',
				'Sales by Machines': 'Sales by Machines',
				Coins: 'Coins',
				Epayment: 'Epayment',
				Manual: 'Manual',
				Washer: 'Washer',
				Dryer: 'Dryer',
				Vending: 'Vending',
				'Bill Acceptor': 'Bill Acceptor',
				January: 'January',
				February: 'February',
				March: 'March',
				April: 'April',
				May: 'May',
				June: 'June',
				July: 'July',
				August: 'August',
				September: 'September',
				October: 'October',
				November: 'November',
				December: 'December',
				'Last 7 Days Sales': 'Last 7 Days Sales',
				Total: 'Total',
				Outlet: 'Outlet',
				Epay: 'Epay',
				'Total Sales': 'Total Sales',
				Idle: 'Idle',
				Running: 'Running',
				Offline: 'Offline',
				'All status': 'All status',
				'Search Outlet': 'Search Outlet',
				'Device status': 'Device status',
				Setting: 'Setting',
				Logout: 'Logout',
				'Sales Performance': 'Sales Performance',
				'All Outlets Yearly Sales': 'All Outlets Yearly Sales',
				'Total Annual Sales': 'Total Annual Sales',
				'Toggle Table': 'Toggle Table',
				'Total Daily Sales': 'Total Daily Sales',
				Filter: 'Filter',
				'Your Sales in': 'Your Sales in',
				'Sales (Coin)': 'Sales (Coin)',
				'Sales (Epay)': 'Sales (Epay)',
				'Total Sales By Outlet in': 'Total Sales By Outlet in',
				'Daily Sales by Outlet': 'Daily Sales by Outlet',
				'Total Income': 'Total Income',
				'Highest Sales Outlet': 'Highest Sales Outlet',
				'Sales Per Day on': 'Sales Per Day on',
				Token: 'Token',
				kg: 'kg',
				'Daily Total Sales': 'Daily Total Sales',
				'Daily Highest Sales Machine': 'Daily Highest Sales Machine',
				'Sales Distribution % by Types': 'Sales Distribution % by Types',
				'Sales Distribution % by Kg': 'Sales Distribution % by Kg',
				Month: 'Month',
				'Machine Name': 'Machine Name',
				Method: 'Method',
				Amount: 'Amount',
				'Machine Utilization': 'Machine Utilization',
				'All Outlets Machine Utilization': 'All Outlets Machine Utilization',
				'Total Runtime of Machines By Outlet': 'Total Runtime of Machines By Outlet',
				'Total Run Time': 'Total Run Time',
				'Highest Run Time Outlet': 'Highest Run Time Outlet',
				'Total Monthly Machine Utilization': 'Total Monthly Machine Utilization',
				Hours: 'hrs',
				'Daily Machine Utilization at': 'Daily Machine Utilization at',
				'Temperature Preference': 'Temperature Preference',
				'Machine Utilization Time': 'Machine Utilization Time',
				cold: 'cold',
				warm: 'warm',
				hot: 'hot',
				other: 'other',
				'washer idle': 'washer idle',
				'washer runtime': 'washer runtime',
				'dryer idle': 'dryer idle',
				'dryer runtime': 'dryer runtime',
				'Remote Activation': 'Remote Activation',
				'Remote Activation / Machine Monitoring': 'Remote Activation / Machine Monitoring',
				Online: 'Online',
				Pay: 'Pay',
				'Loading...': 'Loading...',
				Cancel: 'Cancel',
				Remark: 'Remark',
				Activation: 'Activation',
				Monitoring: 'Monitoring',
				Stop: 'Stop',
				Start: 'Start',
				'End At': 'End At',
				'Start At': 'Start At',
				On: 'On',
				Off: 'Off',
				'Transaction Record': 'Transaction Record',
				'Transactions Record': 'Transactions Record',
				'Choose Method': 'Choose Method',
				Coin: 'Coin',
				All: 'All',
				'Start Date': 'Start Date',
				'End Date': 'End Date',
				'Select Store': 'Select Store',
				Submit: 'Submit',
				Search: 'Search',
				'Outlet Name': 'Outlet Name',
				'Project Code': 'Project Code',
				'Run Time (minutes)': 'Run Time (minutes)',
				Date: 'Date',
				'Start Time': 'Start Time',
				'End Time': 'End Time',
				'Download CSV': 'Download CSV',
				Today: 'Today',
				Yesterday: 'Yesterday',
				'Last seven Days': 'Last seven Days',
				'Last thirty Days': 'Last thirty Days',
				'This month': 'This month',
				'Last month': 'Last month',
				page: 'page',
				'Click to sort ascending': 'Click to sort ascending',
				'Click to sort descending': 'Click to sort descending',
				'Browsable Report': 'Browsable Report',
				Daily: 'Daily',
				Monthly: 'Monthly',
				Alipay: 'Alipay',
				Cash: 'Cash',
				Discount: 'Discount',
				Summary: 'Summary',
				'Download Excel': 'Download Excel',
				'Sales Summary': 'Sales Summary',
				Operator: 'Operator',
				Status: 'Status',
				Time: 'Time',
				'Transaction ID': 'Transaction ID',
				Success: 'Success',
				Refund: 'Refund',
				'Epayment Transaction Record': 'Epayment Transaction Record',
				'Epayment Record': 'Epayment Record',
				'Payee Account': 'Payee Account',
				'No data': 'No data',
				'Error Code Record': 'Error Code Record',
				'System Error Report': 'System Error Report',
				'Manual Pay Record': 'Manual Pay Record',
				'Manual Transaction Record': 'Manual Transaction Record',
				'Bill Record': 'Bill Record',
				'Machines Sales Transactions': 'Machines Sales Transactions',
				'Machines Sales Transaction Report': 'Machines Sales Transaction Report',
				'start time': 'start time',
				'end time': 'end time',
				'Hourly Sales Transaction Report': 'Hourly Sales Transaction Report',
				'Hourly Sales Transactions': 'Hourly Sales Transactions',
				'Price Setting': 'Price Setting',
				Others: 'Others',
				'Machine Logging': 'Machine Logging',
				'Unassigned Outlets': 'Unassigned Outlets',
				'Add Pricing': 'Add Pricing',
				Details: 'Details',
				'Assign To': 'Assign To',
				'Assigned To': 'Assigned To',
				'Created On': 'Created On',
				Description: 'Desc',
				Name: 'Name',
				'Add field': 'Add field',
				'Assign outlets': 'Assign outlets',
				Pricing: 'Pricing',
				'Filter outlets': 'Filter Outlets',
				State: 'State',
				Items: 'Items',
				'Select state': 'Select State',
				Save: 'Save',
				Assigned: 'Assigned',
				'Assigned outlets': 'Assigned outlets',
				'Weight in KG': 'Weight in KG',
				'Cold Price': 'Cold Price',
				'Warm Price': 'Warm Price',
				'Hot Price': 'Hot Price',
				'C-Run Time': 'C-Run Time',
				'W-Run Time': 'W-Run Time',
				'H-Run Time': 'H-Run Time',
				'Default Mode': 'Default Mode',
				'Weight kg': 'Weight kg',
				'Run Time RM1': 'Run Time RM1',
				'Min Amount': 'Min Amount',
				'Max Amount': 'Max Amount',
				'Initial Time': 'Initial Time',
				Promotion: 'Promotion',
				'Promotion Setting': 'Promotion Setting',
				'Assign Promotion to Outlets': 'Assign Promotion to Outlets',
				'Add Promotion': 'Add Promotion',
				'View/Update Promotion': 'View/Update Promotion',
				'Campaign Start Date': 'Campaign Start Date',
				'Campaign End Date': 'Campaign End Date',
				'Valid From': 'Valid From',
				'Valid To': 'Valid To',
				Monday: 'Monday',
				Tuesday: 'Tuesday',
				Wednesday: 'Wednesday',
				Thursday: 'Thursday',
				Friday: 'Friday',
				Saturday: 'Saturday',
				Sunday: 'Sunday',
				'Flat Rate: [cold, hot, warm]': 'Flat Rate: [cold, hot, warm]',
				Percentage: 'Percentage',
				'Rate In Percentage': 'Rate In Percentage',
				'Review Dashboard': 'Review Dashboard',
				'Total Reviews': 'Total Reviews',
				'Total Reviewer': 'Total Reviewer',
				'Outlet Cleanliness': 'Outlet Cleanliness',
				'Washer Quality': 'Washer Quality',
				'Dryer Quality': 'Dryer Quality',
				'Service Quality': 'Service Quality',
				'Overall Score': 'Overall Score',
				'Select Outlet': 'Select Outlet',
				'Select Outlets': 'Select Outlets',
				'Select Year': 'Select Year',
				'Select Month': 'Select Month',
				'Clear All': 'Clear All',
				'Monthly Scores': 'Monthly Scores',
				Clean: 'Clean',
				Service: 'Service',
				'All Reviews': 'All Reviews',
				'No results found': 'No results found',
				'In progress': 'In Progress',
				Pending: 'Pending',
				Resolve: 'Resolve',
				Resolved: 'Resolved',
				'Add Record': 'Add Record',
				'Select record type': 'Select record type',
				Issue: 'Issue',
				Maintenance: 'Maintenance',
				'Add Title': 'Add Title',
				Group: 'Group',
				'Selected Machines': 'Selected Machines',
				'Check All': 'Check All',
				'Max length is 1000': 'Max length is 1000',
				Upload: 'Upload',
				'Outlet Records': 'Outlet Records',
				Machines: 'Machines',
				'Machine Issues': 'Machine Issues',
				'Maintenance Records': 'Maintenance Records',
				Machine: 'Machine',
				Title: 'Title',
				'Machine Type': 'Machine Type',
				Images: 'Images',
				'Image Gallery': 'Image Gallery',
				'Uploaded Images': 'Uploaded Images',
				Update: 'Update',
				Outlets: 'Outlets',
				Dryers: 'Dryers',
				Washers: 'Washers',
				'Add RM Config': 'Add RM Config',
				'List of Outlets': 'List of Outlets',
				Label: 'Label',
				'Client Secret': 'Client Secret',
				'Client ID': 'Client ID',
				'RM Private Key': 'RM Private Key',
				'Create Outlet': 'Create Outlet',
				'Machine Details': 'Machine Details',
				'Outlet Prefix': 'Outlet Prefix',
				'Full Name': 'Full Name',
				Brand: 'Brand',
				Url: 'Url',
				Address: 'Address',
				City: 'City',
				Postcode: 'Postcode',
				'Rm Store ID': 'Rm Store ID',
				'Outlet Step': 'Outlet Step',
				'No. Of Washers': 'No. Of Washers',
				'No. Of Dryers': 'No. Of Dryers',
				'No. Of Vendings': 'No. Of Vendings',
				'No. Of Bill Acceptors': 'No. Of Bill Acceptors',
				'Machine Mode': 'Machine Mode',
				Cloud: 'Cloud',
				'Ten Pulse': 'Ten Pulse',
				'Fifty Cent': 'Fifty Cent',
				'Check Response': 'Check Res',
				'Coin Rate Vending': 'Coin Rate Vending',
				'Payment Gateway Config': 'Payment Gateway Config',
				'Select RM': 'Select RM',
				Next: 'Next',
				'Update Outlet': 'Update Outlet',
				'Update Machine': 'Update Machine',
				'List of Devices': 'List of Devices',
				'Display Name': 'Display Name',
				QR: 'QR',
				'Qr Code': 'Qr Code',
				Download: 'Download',
				'Download All': 'Download All',
				'Coin Rate': 'Coin Rate',
				'One Run Time': 'One Run Time',
				'Select Washer Type': 'Select Washer Type',
				Type: 'Type',
				'Partner ID': 'Partner ID',
				'Slot 1 Name': 'Slot 1 Name',
				'Slot 2 Name': 'Slot 2 Name',
				'Slot 3 Name': 'Slot 3 Name',
				'Slot 4 Name': 'Slot 4 Name',
				'Slot 5 Name': 'Slot 5 Name',
				'Slot 6 Name': 'Slot 6 Name',
				Select: 'Select',
				Previous: 'Previous',
				Complete: 'Complete',
				'Operator Login': 'Operator Login',
				'Please enter your user name and password to login': 'Please enter your user name and password to login',
				Login: 'Login',
				'Terms & Conditions': 'Terms & Conditions',
				'Privacy & Policy': 'Privacy & Policy',
				'Distributor Login': 'Distributor Login',
				'Staff Login': 'Staff Login',
				'Create your account': 'Create your account',
				Username: 'Username',
				Email: 'Email',
				'Brand Name': 'Brand Name',
				Password: 'Password',
				'Confirm Password': 'Confirm Password',
				'Create Account': 'Create Account',
				'Already have an account?': 'Already have an account?',
				'By signing up you agree to our': 'By signing up you agree to our',
				'Terms &': 'Terms &',
				Policy: 'Policy',
				'Sign in': 'Sign in',
				'Loyalty Admin': 'Loyalty Admin',
				'Utility Monitoring': 'Utility Monitoring',
				//

				'Total Bills By Outlet': 'Total Bills By Outlet',
				'Machine Sales Transaction Report': 'Machine Sales Transaction Report',
				'Total Amount': 'Total Amount',
				'Actual Amount': 'Actual Amount',
				'Sales by Machine Type': 'Sales by Machine Type',
				'Are you sure to delete this pricing?': 'Are you sure to delete this pricing?',
				'Successfully created': 'Successfully created',
				'Successfully assigned price': 'Successfully assigned price',
				'Pricing Details': 'Pricing Details',
				Detergent: 'Detergent',
				Softener: 'Softener',
				'Laundry Bag': 'Laundry Bag',
				Bleach: 'Bleach',
				'Dry Softener': 'Dry Softener',
				Yes: 'Yes',
				No: 'No',
				'Select date': 'Select date',
				'Select time': 'Select time',
				'Rate in flat rate': 'Rate in flat rate',
				Action: 'Action',
				Number: 'Number',
				Comment: 'Comment',
				'Activation Date': 'Activation Date',
				'Warranty Year': 'Warranty Year',
				'Warranty Status': 'Warranty Status',
				'Serial Number': 'Serial Number',
				'Add Warranty': 'Add Warranty',
				'View Warranty': 'View Warranty',
				'Records Details': 'Records Details',
				'Add Year': 'Add Year',
				'Add Serial Number': 'Add Serial Number',
				'years limited': 'years limited',
				warranty: 'warranty',
				certificate: 'certificate',
				'Coin Collection': 'Coin Collection',
				'No Outlet': 'No Outlet',
				'Cutoff Date': 'Cutoff Date',
				'Submit Date': 'Submit Date',
				'Assign Machine': 'Assign Machine',
				'Select All': 'Select All',
				'PIC Role': 'PIC Role',
				'PIC ID': 'PIC ID',
				'PIC Name': 'PIC Name',
				Completed: 'Completed',
				'Start Collection': 'Start Collection',
				'Create Machine Group': 'Create Machine Group',
				'Machine List': 'Machine List',
				'Cutoff Machine': 'Cutoff Machine',
				'Submit Amount': 'Submit Amount',
				'Submitted Amount': 'Submitted Amount',
				'Bulk Machines Cutoff': 'Bulk Machines Cutoff',
				'Enable Create Group Machine': 'Enable Create Group Machine',
				'Add Group': 'Add Group',
				Add: 'Add',
				View: 'View',
				'Top Outlets': 'Top Outlets',
				'View Outlet': 'View Outlet',
				'Outlet Online Status': 'Outlet Online Status',
				'Machine Online Status': 'Machine Online Status',
				Required: 'Required',

				'Add Machine Details': 'Add Machine Details',
				'Manage User': 'Manage User',
				'RM Configuration': 'RM Configuration',
				'Create RM Config': 'Create RM Config',
				'Update Machine Details': 'Update Machine Details',
				'Receive Mail': 'Receive Mail',
				Active: 'Active',
				'Created At': 'Created At',
				'Add User': 'Add User',
				User: 'User',
				Staff: 'Staff',
				'Select user type': 'Select user type',
				'Select Operator': 'Select Operator',
				'Select Role': 'Select Role',
				Activated: 'Activated',
				Deactivated: 'Deactivated',
				'Outlets Permissions': 'Outlets Permissions',
				'Update User': 'Update User',
				'Created Role': 'Created Role',
				'Created By': 'Created By',
				'Add Role': 'Add Role',
				Optional: 'Optional',
				'Laundro Permissions': 'Laundro Permissions',
				'Edit Role': 'Edit Role',
				'Select Dryer Type': 'Select Dryer Type',
				'All Users': 'All Users',
				'Roles List': 'Roles List',
				'The two passwords that you entered do not match!': 'The two passwords that you entered do not match!',
				'Add Machine Group': 'Add Machine Group',
				'Edit Machine Group': 'Edit Machine Group',
				Distributor: 'Distributor',
				Bill: 'Bill',
				'Meter Reading': 'Meter Reading',
				'Not Assigned': 'Not Assigned',
			},
		},
		zh: {
			translation: {
				'Summary Dashboard': '摘要仪表板',
				Dashboard: '仪表板',
				'Outlets Online': '店铺在线',
				'Outlets Offline': '店铺离线',
				'Machines Running': '机器运行',
				'Machines Idle': '机器闲置',
				'Machines Offline': '机器离线',
				'Sales by Channels': '通过渠道销售',
				'Sales by Machines': '通过机器销售',
				Coins: '硬币',
				Epayment: '电子支付',
				Manual: '手动的',
				Washer: '洗衣机',
				Dryer: '烘干机',
				Vending: '自动贩卖机',
				'Bill Acceptor': '纸币分配器',
				'Total Annual Sales': '年总销售额',
				January: '一月',
				February: '二月',
				March: '三月',
				April: '四月',
				May: '五月',
				June: '六月',
				July: '七月',
				August: '八月',
				September: '九月',
				October: '十月',
				November: '十一月',
				December: '十二月',
				'Last 7 Days Sales': '最近七天的销售',
				Total: '总数',
				Outlet: '店铺',
				Epay: '电子支付',
				'Total Sales': '总销售额',
				Idle: '闲置的',
				Running: '正在运行',
				Offline: '离线',
				'All status': '所有状态',
				'Search Outlet': '搜索商店',
				'Device status': '设备状态',
				Setting: '设置',
				Logout: '登出',
				'Sales Performance': '销售业绩',
				'All Outlets Yearly Sales': '所有商店年度销售',
				'Toggle Table': '切换表',
				'Total Daily Sales': '每日总销售额',
				Filter: '筛选',
				'Your Sales in': '您的销售',
				'Sales (Coin)': '销售（硬币）',
				'Sales (Epay)': '销售（Epay）',
				'Total Sales By Outlet in': '店铺总销售额',
				'Daily Sales by Outlet': '店铺每日销售额',
				'Total Income': '总收入',
				'Highest Sales Outlet': '最高销售的店铺',
				'Sales Per Day on': '每天销售',
				Token: '电子代币',
				kg: '公斤',
				'Daily Total Sales': '每日总销售',
				'Daily Highest Sales Machine': '每日最高销售机器',
				'Sales Distribution % by Types': '按类型按销售分配％',
				'Sales Distribution % by Kg': '销售分配％kg',
				Month: '月',
				'Machine Name': '机器名称',
				Method: '方法',
				Amount: '数量',
				Date: '日期',
				'Machine Utilization': '机器利用率',
				'All Outlets Machine Utilization': '所以店铺机器使用率',
				'Total Runtime of Machines By Outlet': '店铺的机器总运行时间',
				'Total Run Time': '总运行时间',
				'Highest Run Time Outlet': '运行时间最高的店铺',
				'Total Monthly Machine Utilization': '每月的总机器利用率',
				Hours: '小时',
				'Daily Machine Utilization at': '每日机器利用率',
				'Temperature Preference': '温度偏好',
				'Machine Utilization Time': '机器利用时间',
				cold: '寒冷的',
				warm: '温暖的',
				hot: '热的',
				other: '其他',
				'washer idle': '洗衣机闲置',
				'washer runtime': '洗衣机运行时间',
				'dryer idle': '干衣机闲置',
				'dryer runtime': '干衣机运行时间',
				'Remote Activation': '远程激活',
				'Remote Activation / Machine Monitoring': '远程激活 /机器监控',
				Online: '在线的',
				Pay: '支付',
				'Loading...': '加载...',
				Cancel: '取消',
				Remark: '评论',
				Activation: '激活',
				Monitoring: '监视',
				Stop: '停止',
				Start: '开始',
				'End At': '结束',
				'Start At': '开始',
				On: '开',
				Off: '关',
				'Transaction Record': '交易记录',
				'Transactions Record': '交易记录',
				'Choose Method': '选择方法',
				Coin: '硬币',
				All: '全部',
				'Start Date': '开始日期',
				'End Date': '结束日期',
				'Select Store': '选择店铺',
				Submit: '提交',
				Search: '搜索',
				'Outlet Name': '店铺名称',
				'Project Code': '项目代码',
				'Run Time (minutes)': '项目代码',
				Date: '运行时间 (分钟)',
				'Start Time': '开始时间',
				'End Time': '结束时间',
				'Download CSV': '下载CSV',
				Today: '今天',
				Yesterday: '昨天',
				'Last seven Days': '最近七天',
				'Last thirty Days': '最近三十天',
				'This month': '这个月',
				'Last month': '上个月',
				page: '页',
				'Click to sort ascending': '点击以顺序排序',
				'Click to sort descending': '点击以逆序排序',
				'Browsable Report': '可浏览报告',
				Daily: '每日',
				Monthly: '每月',
				Alipay: '支付宝',
				Cash: '现金',
				Discount: '全部的',
				Summary: '概括',
				'Download Excel': '下载Excel',
				'Sales Summary': '销售摘要',
				Operator: '操作员',
				Status: '状态',
				Time: '时间',
				'Transaction ID': '交易ID',
				Success: '成功',
				Refund: '退款',
				'Epayment Transaction Record': '付款交易记录',
				'Epayment Record': '付款记录',
				'Payee Account': '收款人帐户',
				'No data': '没有数据',
				'Error Code Record': '错误码记录',
				'System Error Report': '系统错误报告',
				'Manual Pay Record': '手动付款记录',
				'Manual Transaction Record': '手动交易记录',
				'Bill Record': '账单记录',
				'Machines Sales Transactions': '机器销售交易',
				'Machines Sales Transaction Report': '机器销售交易报告',
				'start time': '开始时间',
				'end time': '结束时间',
				'Hourly Sales Transaction Report': '小时销售交易报告',
				'Hourly Sales Transactions': '小时销售交易',
				'Price Setting': '价格设定',
				Others: '其他的',
				'Machine Logging': '未分配的商店',
				'Unassigned Outlets': '未分配的商店',
				'Add Pricing': '增加定价',
				Details: '细节',
				'Assign To': '分配给',
				'Assigned To': '分配给',
				'Created On': '创建于',
				Description: '描述',
				Name: '姓名',
				'Add field': '添加字段',
				'Assign outlets': '分配商店',
				Pricing: '价钱',
				'Filter outlets': '过滤器店',
				State: '状态',
				Items: '项目',
				'Select state': '选择状态',
				Save: '节省',
				Assigned: '分配',
				'Assigned outlets': '指定店铺',
				'Weight in KG': '重量为kg',
				'Cold Price': '冷价格',
				'Warm Price': '温暖的价格',
				'Hot Price': '高价',
				'C-Run Time': 'C-run时间',
				'W-Run Time': 'w-run时间',
				'H-Run Time': 'H-run时间',
				'Default Mode': '默认模式',
				'Weight kg': '体重公斤',
				'Run Time RM1': '运行时间RM1',
				'Min Amount': '最小金额',
				'Max Amount': '最大金额',
				'Initial Time': '初始时间',
				Promotion: '促销',
				'Promotion Setting': '促销设置',
				'Assign Promotion to Outlets': '将晋升分配给媒体',
				'Add Promotion': '添加促销',
				'View/Update Promotion': '查看/更新促销',
				'Campaign Start Date': '活动开始日期',
				'Campaign End Date': '竞选结束日期',
				'Valid From': '有效',
				'Valid To': '有效',
				Monday: '周一',
				Tuesday: '周二',
				Wednesday: '周三',
				Thursday: '周四',
				Friday: '星期五',
				Saturday: '周六',
				Sunday: '星期日',
				'Flat Rate: [cold, hot, warm]': '统一费率：[冷，热，温暖]',
				Percentage: '百分比',
				'Rate In Percentage': '百分比率',
				'Review Dashboard': '反馈概况',
				'Total Reviews': '总评论',
				'Total Reviewer': '总审稿人',
				'Outlet Cleanliness': '商店清洁度',
				'Washer Quality': '洗衣机质量',
				'Dryer Quality': '烘干机质量',
				'Service Quality': '服务质量',
				'Overall Score': '总分',
				'Select Outlet': '选择商店',
				'Select Outlets': '选择商店',
				'Select Year': '选择年份',
				'Select Month': '选择月份',
				'Clear All': '清除全部',
				'Monthly Scores': '每月分数',
				Clean: '干净',
				Service: '服务',
				'All Reviews': '所有评论',
				'No results found': '未找到相关词',
				'In progress': '进行中',
				Pending: '待办的',
				Resolve: '解决',
				Resolved: '已解决',
				'Add Record': '添加记录',
				'Select record type': '选择记录类型',
				Issue: '问题',
				Maintenance: '维护',
				'Add Title': '添加标题',
				Group: '创建组',
				'Selected Machines': '选定的机器',
				'Check All': '选择所有',
				'Max length is 1000': '最大长度为1000',
				Upload: '上传',
				'Outlet Records': '店铺记录',
				Machines: '机器',
				'Machine Issues': '机器问题',
				'Maintenance Records': '维护记录',
				'Display Name': '显示名称',
				Machine: '机器',
				Title: '标题',
				'Machine Type': '机器的种类',
				Images: '图片',
				Update: '更新',
				'Image Gallery': '图片库',
				'Uploaded Images': '上传的图像',
				Outlets: '店铺',
				Dryers: '干衣机',
				Washers: '洗衣机',
				'Add RM Config': '添加RM配置',
				'List of Outlets': '店铺列表',
				Label: '标签',
				'Client Secret': '客户秘密',
				'Client ID': '客户ID',
				'RM Private Key': 'RM私钥',
				'Create Outlet': '創建店鋪',
				'Machine Details': '机器详细信息',
				'Outlet Prefix': '店铺词头',
				'Full Name': '全名',
				Brand: '品牌',
				Url: '网址',
				Address: '地址',
				City: '城市',
				Postcode: '邮政编码',
				'Rm Store ID': 'RM商店ID',
				'Outlet Step': '店铺代币单位',
				'No. Of Washers': '洗衣机数量',
				'No. Of Dryers': '烘干机数量',
				'No. Of Vendings': '售货机数量',
				'No. Of Bill Acceptors': '纸币器数量',
				'Machine Mode': '机器模式',
				Cloud: '云',
				'Ten Pulse': '十脉冲',
				'Fifty Cent': '五十分',
				'Check Response': '检查响应',
				'Coin Rate Vending': '硬币利率自动售货',
				'Payment Gateway Config': '支付网关配置',
				'Select RM': '选择RM',
				Next: '下一个',
				'Update Outlet': '更新出口',
				'Update Machine': '更新店铺',
				'List of Devices': '设备列表',
				QR: '二维码',
				'Qr Code': '二维码',
				Download: '下载',
				'Download All': '全部下载',
				'Coin Rate': '硬币率',
				'One Run Time': '运行时',
				'Select Washer Type': '选择洗衣机类型',
				Type: '类型',
				'Partner ID': '合作伙伴ID',
				'Slot 1 Name': '插槽一名称',
				'Slot 2 Name': '插槽二名称',
				'Slot 3 Name': '插槽三名称',
				'Slot 4 Name': '插槽四名称',
				'Slot 5 Name': '插槽五名称',
				'Slot 6 Name': '插槽六名称',
				Select: '选择',
				Previous: '以前的',
				Complete: '完全的',
				'Operator Login': '店主登录',
				'Please enter your user name and password to login': '请输入你的用户名和密码以登录',
				Login: '登录',
				'Terms & Conditions': '条款和条件',
				'Privacy & Policy': '隐私政策',
				'Distributor Login': '经销商登录',
				'Staff Login': '员工登录',
				'Create your account': '注册账号',
				Username: '用户名',
				Email: '电子邮件',
				'Brand Name': '品牌',
				Password: '密码',
				'Confirm Password': '确认密码',
				'Create Account': '账号注册',
				'Already have an account?': '已经有注册账号?',
				'By signing up you agree to our': '通过注册您同意我们的',
				'Terms &': '条款和',
				Policy: '政策',
				'Sign in': '请登录',
				'Loyalty Admin': '忠诚管理员',
				'Utility Monitoring': '实用品监控',
				'Machine Sales Transaction Report': '机器销售交易报告',
				'Total Bills By Outlet': '按商店划分的总账单',
				'Total Amount': '总金额',
				'Actual Amount': '实际金额',
				'Sales by Machine Type': '按机器类型销售',
				'Are you sure to delete this pricing?': '您一定会删除这个价格吗?',
				'Successfully created': '成功创建',
				'Successfully assigned price': '成功分配的价格',
				'Pricing Details': '定价细节',
				Detergent: '洗涤剂',
				Softener: '柔软剂',
				'Laundry Bag': '洗衣袋',
				Bleach: '漂白',
				'Dry Softener': '干性软化剂',
				Yes: '是的',
				No: '不',
				'Select date': '选择日期',
				'Select time': '选择时间',
				'Rate in flat rate': '统一费率',
				Action: '行动',
				Number: '数字',
				Comment: '评论',
				'Activation Date': '激活日期',
				'Warranty Year': '保修年',
				'Warranty Status': '质保状态',
				'Serial Number': '序列号',
				'Add Warranty': '添加保修',
				'View Warranty': '查看保修',
				'Records Details': '记录详细信息',
				'Add Year': '添加年',
				'Add Serial Number': '添加序列号',
				'years limited': '限量',
				warranty: '保修单',
				certificate: '证书',
				'Coin Collection': '钱币收藏',
				'No Outlet': '没有商店',
				'Cutoff Date': '截止日期',
				'Submit Date': '提交日期',
				'Assign Machine': '分配机器',
				'Select All': '全选',
				'PIC Role': '负责人角色',
				'PIC ID': '负责人身份证',
				'PIC Name': '负责人姓名',
				Completed: '完全的',
				'Start Collection': '开始收集',
				'Create Machine Group': '创建机器组',
				'Machine List': '机器列表',
				'Cutoff Machine': '截止机',
				'Submit Amount': '提交金额',
				'Submitted Amount': '提交金额',
				'Bulk Machines Cutoff': '散装机器截止',
				'Enable Create Group Machine': '启用创建组机器',
				'Add Group': '添加组',
				Add: '添加',
				View: '看法',
				'Top Outlets': '顶级商店',
				'View Outlet': '查看商店',
				'Outlet Online Status': '网上商店状态',
				'Machine Online Status': '机器在线状态',
				Required: '必需的',

				'Add Machine Details': '添加机器详细信息',
				'Manage User': '管理用户',
				'RM Configuration': 'RM 配置',
				'Create RM Config': '创建 RM 配置',
				'Update Machine Details': '更新机器详细信息',
				'Receive Mail': '接收邮件',
				Active: '积极的',
				'Created At': '创建在',
				'Add User': '添加用户',
				User: '用户',
				Staff: '职员',
				'Select user type': '选择用户类型',
				'Select Operator': '选择操作员',
				'Select Role': '选择角色',
				Activated: '活性',
				Deactivated: '停用',
				'Outlets Permissions': '商店权限',
				'Update User': '更新用户',
				'Created Role': '创建的角色',
				'Created By': '由制作',
				'Add Role': '添加角色',
				Optional: '选修的',
				'Laundro Permissions': 'Laundro权限',
				'Edit Role': '编辑角色',
				'Select Dryer Type': '选择干燥机类型',
				'All Users': '全部用户',
				'Roles List': '角色列表',
				'The two passwords that you entered do not match!': '您输入的两个密码不匹配!',
				'Add Machine Group': '添加机器组',
				'Edit Machine Group': '编辑机器组',
				Distributor: '分销商',
				Bill: '账单',
				'Meter Reading': '仪表读数',
				'Not Assigned': '未分配',
			},
		},
		zhtw: {
			translation: {
				'Summary Dashboard': '摘要儀表板',
				Dashboard: '儀表板',
				'Outlets Online': '店鋪在線',
				'Outlets Offline': '店鋪離線',
				'Machines Running': '機器運行',
				'Machines Idle': '機器閒置',
				'Machines Offline': '機器離線',
				'Sales by Channels': '通過渠道銷售',
				'Sales by Machines': '通過機器銷售',
				Coins: '硬幣',
				Epayment: '電子支付',
				Manual: '手動的',
				Washer: '洗衣機',
				Dryer: '乾衣機',
				Vending: '自動販賣機',
				'Bill Acceptor': '紙幣分配器',
				'Total Annual Sales': '年總銷售額',
				January: '一月',
				February: '二月',
				March: '三月',
				April: '四月',
				May: '五月',
				June: '六月',
				July: '七月',
				August: '八月',
				September: '九月',
				October: '十月',
				November: '十一月',
				December: '十二月',
				'Last 7 Days Sales': '最近七天的銷售',
				Total: '總數',
				Outlet: '店鋪',
				Epay: '電子支付',
				'Total Sales': '總銷售額',
				Idle: '閒置的',
				Running: '正在運行',
				Offline: '離線',
				'All status': '所有狀態',
				'Search Outlet': '搜索商店',
				'Device status': '設備狀態',
				Setting: '設置',
				Logout: '登出',
				'Sales Performance': '銷售成績',
				'All Outlets Yearly Sales': '所有商店年度銷售',
				'Toggle Table': '切換錶',
				'Total Daily Sales': '每日總銷售額',
				Filter: '篩選',
				'Your Sales in': '您的銷售',
				'Sales (Coin)': '銷售（硬幣）',
				'Sales (Epay)': '銷售（Epay）',
				'Total Sales By Outlet in': '店鋪總銷售額',
				'Daily Sales by Outlet': '店鋪每日銷售額',
				'Total Income': '總收入',
				'Highest Sales Outlet': '最高銷售的店鋪',
				'Sales Per Day on': '每天銷售',
				Token: '電子代幣',
				kg: '公斤',
				'Daily Total Sales': '每日總銷售',
				'Daily Highest Sales Machine': '每日最高銷售機器',
				'Sales Distribution % by Types': '銷售分配％按類型',
				'Sales Distribution % by Kg': '銷售分配％kg',
				Month: '月',
				'Machine Name': '機器名稱',
				Method: '方法',
				Amount: '數量',
				Date: '日期',
				'Machine Utilization': '機器利用率',
				'All Outlets Machine Utilization': '所以店鋪機器使用率',
				'Total Runtime of Machines By Outlet': '店舖的機器總運行時間',
				'Total Run Time': '總運行時間',
				'Highest Run Time Outlet': '運行時間最高的店鋪',
				'Total Monthly Machine Utilization': '每月的總機器利用率',
				Hours: '小時',
				'Daily Machine Utilization at': '每日機器利用率',
				'Temperature Preference': '溫度偏好',
				'Machine Utilization Time': '機器利用時間',
				cold: '寒冷的',
				warm: '溫暖的',
				hot: '熱的',
				other: '其他',
				'washer idle': '洗衣機閒置',
				'washer runtime': '洗衣機運行時間',
				'dryer idle': '乾衣機閒置',
				'dryer runtime': '乾衣機運行時間',
				'Remote Activation': '遠程激活',
				'Remote Activation / Machine Monitoring': '遠程激活 /機器監控',
				Online: '在線的',
				Pay: '支付',
				'Loading...': '加載',
				Cancel: '取消',
				Remark: '評論',
				Activation: '激活',
				Monitoring: '監視',
				Stop: '停止',
				Start: '開始',
				'End At': '結束',
				'Start At': '開始',
				On: '開',
				Off: '關',
				'Transaction Record': '交易記錄',
				'Transactions Record': '交易記錄',
				'Choose Method': '選擇方法',
				Coin: '硬幣',
				All: '全部',
				'Start Date': '開始日期',
				'End Date': '結束日期',
				'Select Store': '選擇店鋪',
				Submit: '提交',
				Search: '搜索',
				'Outlet Name': '店鋪名称',
				'Project Code': '項目代碼',
				'Run Time (minutes)': '項目代碼',
				Date: '運行時間 (分鐘)',
				'Start Time': '開始時間',
				'End Time': '結束時間',
				'Download CSV': '下載CSV',
				Today: '今天',
				Yesterday: '昨天',
				'Last seven Days': '最近七天',
				'Last thirty Days': '最近三十天',
				'This month': '這個月',
				'Last month': '上個月',
				page: '頁',
				'Click to sort ascending': '點擊以順序排序',
				'Click to sort descending': '點擊以逆序排序',
				'Browsable Report': '可瀏覽報告',
				Daily: '每日',
				Monthly: '每月',
				Alipay: '支付寶',
				Cash: '現金',
				Discount: '折扣',
				Summary: '概括',
				'Download Excel': '下載Excel',
				'Sales Summary': '銷售摘要',
				Operator: '操作員',
				Status: '狀態',
				Time: '時間',
				'Transaction ID': '交易ID',
				Success: '成功',
				Refund: '退款',
				'Epayment Transaction Record': '付款交易記錄',
				'Epayment Record': '付款記錄',
				'Payee Account': '收款人帳戶',
				'No data': '沒有數據',
				'Error Code Record': '錯誤碼記錄',
				'System Error Report': '系統錯誤報告',
				'Manual Pay Record': '手動付款記錄',
				'Manual Transaction Record': '手動交易記錄',
				'Bill Record': '賬單記錄',
				'Machines Sales Transactions': '機器銷售交易',
				'Machines Sales Transaction Report': '機器銷售交易報告',
				'start time': '開始時間',
				'end time': '結束時間',
				'Hourly Sales Transaction Report': '小時銷售交易報告',
				'Hourly Sales Transactions': '小時銷售交易',
				'Price Setting': '價格設定',
				Others: '其他的',
				'Machine Logging': '未分配的商店',
				'Unassigned Outlets': '未分配的商店',
				'Add Pricing': '增加定價',
				Details: '細節',
				'Assign To': '分配給',
				'Assigned To': '分配給',
				'Created On': '創建於',
				Description: '描述',
				Name: '姓名',
				'Add field': '添加字段',
				'Assign outlets': '分配商店',
				Pricing: '價錢',
				'Filter outlets': '過濾器店',
				State: '狀態',
				Items: '項目',
				'Select state': '選擇狀態',
				Save: '節省',
				Assigned: '分配',
				'Assigned outlets': '指定店鋪',
				'Weight in KG': '重量為kg',
				'Cold Price': '冷價格',
				'Warm Price': '溫暖的價格',
				'Hot Price': '高價',
				'C-Run Time': 'C-run時間',
				'W-Run Time': 'w-run時間',
				'H-Run Time': 'H-run時間',
				'Default Mode': '默認模式',
				'Weight kg': '體重公斤',
				'Run Time RM1': '運行時間RM1',
				'Min Amount': '最小金額',
				'Max Amount': '最大金額',
				'Initial Time': '初始時間',
				Promotion: '促銷',
				'Promotion Setting': '促銷設置',
				'Assign Promotion to Outlets': '將晉升分配給媒體',
				'Add Promotion': '添加促銷',
				'View/Update Promotion': '查看/更新促銷',
				'Campaign Start Date': '活動開始日期',
				'Campaign End Date': '競選結束日期',
				'Valid From': '有效',
				'Valid To': '有效',
				Monday: '週一',
				Tuesday: '週二',
				Wednesday: '週三',
				Thursday: '週四',
				Friday: '星期五',
				Saturday: '週六',
				Sunday: '星期日',
				'Flat Rate: [cold, hot, warm]': '統一費率：[冷，熱，溫暖]',
				Percentage: '百分比',
				'Rate In Percentage': '百分比率',
				'Review Dashboard': '查看儀表板',
				'Total Reviews': '總評價',
				'Total Reviewer': '總審稿人',
				'Outlet Cleanliness': '商店清潔度',
				'Washer Quality': '洗衣機質量',
				'Dryer Quality': '烘乾機質量',
				'Service Quality': '服務質量',
				'Overall Score': '總分',
				'Select Outlet': '選擇商店',
				'Select Outlets': '選擇商店',
				'Select Year': '選擇年份',
				'Select Month': '選擇月份',
				'Clear All': '清除全部',
				'Monthly Scores': '每月分數',
				Clean: '乾淨',
				Service: '服務',
				'All Reviews': '所有評論',
				'No results found': '未找到相關詞',
				'In progress': '進行中',
				Pending: '待辦的',
				Resolve: '解決',
				Resolved: '已解決',
				'Add Record': '添加記錄',
				'Select record type': '選擇記錄類型',
				Issue: '問題',
				Maintenance: '維護',
				'Add Title': '添加標題',
				Group: '團體',
				'Selected Machines': '選定的機器',
				'Check All': '選擇所有',
				'Max length is 1000': '最大長度為1000',
				Upload: '上傳',
				'Outlet Records': '店鋪記錄',
				Machines: '機器',
				'Machine Issues': '機器問題',
				'Maintenance Records': '維護記錄',
				'Display Name': '顯示名稱',
				Title: '標題',
				'Machine Type': '機器的種類',
				Images: '圖片',
				Update: '更新',
				'Image Gallery': '圖片庫',
				'Uploaded Images': '上傳的圖像',
				Outlets: '店鋪',
				Dryers: '乾衣機',
				Washers: '洗衣機',
				'Add RM Config': '添加RM配置',
				'List of Outlets': '店鋪列表',
				Label: '標籤',
				'Client Secret': '客戶秘密',
				'Client ID': '客戶ID',
				'RM Private Key': 'RM私鑰',
				'Create Outlet': '創建店鋪',
				'Machine Details': '機器詳細信息',
				'Outlet Prefix': '店鋪詞頭',
				'Full Name': '全名',
				Brand: '品牌',
				Url: '網址',
				Address: '地址',
				City: '城市',
				Postcode: '郵政編碼',
				'Rm Store ID': 'RM商店ID',
				'Outlet Step': '店鋪代幣單位',
				'No. Of Washers': '洗衣機數量',
				'No. Of Dryers': '烘乾機數量',
				'No. Of Vendings': '售貨機數量',
				'No. Of Bill Acceptors': '紙幣器數量',
				'Machine Mode': '機器模式',
				Cloud: '雲端',
				'Ten Pulse': '十脈衝',
				'Fifty Cent': '五十分',
				'Check Response': '檢查響應',
				'Coin Rate Vending': '硬幣利率自動售貨',
				'Payment Gateway Config': '支付網關配置',
				'Select RM': '選擇RM',
				Next: '下一個',
				'Update Outlet': '更新出口',
				'Update Machine': '更新店鋪',
				'List of Devices': '設備列表',
				Machine: '機器',
				QR: '二維碼',
				'Qr Code': '二維碼',
				Download: '下載',
				'Download All': '全部下載',
				'Coin Rate': '硬幣率',
				'One Run Time': '運行時',
				'Select Washer Type': '選擇洗衣機類型',
				Type: '類型',
				'Partner ID': '合作夥伴ID',
				'Slot 1 Name': '插槽一名稱',
				'Slot 2 Name': '插槽二名稱',
				'Slot 3 Name': '插槽三名稱',
				'Slot 4 Name': '插槽四名稱',
				'Slot 5 Name': '插槽五名稱',
				'Slot 6 Name': '插槽六名稱',
				Select: '選擇',
				Previous: '以前的',
				Complete: '完全的',
				'Operator Login': '店主登錄',
				'Please enter your user name and password to login': '請輸入你的用戶名和密碼以登錄',
				Login: '登錄',
				'Terms & Conditions': '條款和條件',
				'Privacy & Policy': '隱私政策',
				'Distributor Login': '經銷商登入',
				'Staff Login': '員工登錄',
				'Create your account': '註冊賬號',
				Username: '用戶名',
				Email: '電子郵件',
				'Brand Name': '品牌',
				Password: '密碼',
				'Confirm Password': '確認密碼',
				'Create Account': '賬號註冊',
				'Already have an account?': '已經有註冊賬號?',
				'By signing up you agree to our': '註冊即表示您同意我們的',
				'Terms &': '條款和',
				Policy: '政策',
				'Sign in': '請登錄',
				'Loyalty Admin': '忠誠管理員',
				'Utility Monitoring': '实用品监控',
				//
				'Machine Sales Transaction Report': '機器銷售交易報告',
				'Total Bills By Outlet': '按商店劃分的總帳單',
				'Total Amount': '總金額',
				'Actual Amount': '實際金額',
				'Sales by Machine Type': '按機器類型銷售',
				'Are you sure to delete this pricing?': '您一定會刪除這個價格嗎？',
				'Successfully created': '成功創建',
				'Successfully assigned price': '成功分配的價格',
				'Pricing Details': '定價細節',
				Detergent: '洗滌劑',
				Softener: '柔軟精',
				'Laundry Bag': '洗衣袋',
				Bleach: '漂白',
				'Dry Softener': '乾性軟化劑',
				Yes: '是的',
				No: '不',
				'Select date': '選擇日期',
				'Select time': '選擇時間',
				'Rate in flat rate': '統一費率',
				Action: '行動',
				Number: '數字',
				Comment: '評論',
				'Activation Date': '激活日期',
				'Warranty Year': '保修年',
				'Warranty Status': '質保狀態',
				'Serial Number': '序列號',
				'Add Warranty': '添加保修',
				'View Warranty': '查看保修',
				'Records Details': '記錄詳細信息',
				'Add Year': '添加年',
				'Add Serial Number': '添加序列號',
				'years limited': '限量',
				warranty: '保修單',
				certificate: '證書',
				'Coin Collection': '錢幣收藏',
				'No Outlet': '沒有商店',
				'Cutoff Date': '截止日期',
				'Submit Date': '提交日期',
				'Assign Machine': '分配機器',
				'Select All': '全選',
				'PIC Role': '負責人角色',
				'PIC ID': '負責人身分證',
				'PIC Name': '負責人姓名',
				Completed: '完全的',
				'Start Collection': '開始收集',
				'Create Machine Group': '創建機器組',
				'Machine List': '機器列表',
				'Cutoff Machine': '截止機器',
				'Submit Amount': '提交金額',
				'Submitted Amount': '提交金額',
				'Bulk Machines Cutoff': '散裝機器截止',
				'Enable Create Group Machine': '啟用創建組機器',
				'Add Group': '添加組',
				Add: '添加',
				View: '看法',
				'Top Outlets': '頂級商店',
				'View Outlet': '查看商店',
				'Outlet Online Status': '網上商店狀態',
				'Machine Online Status': '機器在線狀態',
				Required: '必需的',

				'Add Machine Details': '添加機器詳細信息',
				'Manage User': '管理用戶',
				'RM Configuration': 'RM 配置',
				'Create RM Config': '創建 RM 配置',
				'Update Machine Details': '更新機器詳細信息',
				'Receive Mail': '接收郵件',
				Active: '積極的',
				'Created At': '創建在',
				'Add User': '添加用戶',
				User: '用戶',
				Staff: '職員',
				'Select user type': '選擇用戶類型',
				'Select Operator': '選擇操作員',
				'Select Role': '選擇角色',
				Activated: '活性',
				Deactivated: '停用',
				'Outlets Permissions': '商店權限',
				'Update User': '更新用戶',
				'Created Role': '創建的角色',
				'Created By': '由製作',
				'Add Role': '添加角色',
				Optional: '選修的',
				'Laundro Permissions': 'Laundro權限',
				'Edit Role': '編輯角色',
				'Select Dryer Type': '選擇乾燥機類型',
				'All Users': '全部用戶',
				'Roles List': '角色列表',
				'The two passwords that you entered do not match!': '您輸入的兩個密碼不匹配!',
				'Add Machine Group': '添加機器組',
				'Edit Machine Group': '編輯機器組',
				Distributor: '分銷商',
				Bill: '賬單',
				'Meter Reading': '儀表讀數',
				'Not Assigned': '未分配',
			},
		},
		ms: {
			translation: {
				'Summary Dashboard': 'Ringkasan papan pemuka',
				Dashboard: 'Papan pemuka',
				'Outlets Online': 'Kedai atas talian',
				'Outlets Offline': 'Kedai luar talian',
				'Machines Running': 'Mesin berjalan',
				'Machines Idle': 'Mesin terbiar',
				'Machines Offline': 'Mesin di luar talian',
				'Sales by Channels': 'Jualan oleh saluran',
				'Sales by Machines': 'Jualan oleh mesin',
				Coins: 'Duit syiling',
				Epayment: 'Epayment',
				Manual: 'Manual',
				Washer: 'Mesin basuh',
				Dryer: 'Pengering',
				Vending: 'Layan diri',
				'Bill Acceptor': 'Mesin Token',
				January: 'Januari',
				February: 'Februari',
				March: 'Mac',
				April: 'April',
				May: 'Mei',
				June: 'Jun',
				July: 'Julai',
				August: 'Ogos',
				September: 'September',
				October: 'Oktober',
				November: 'November',
				December: 'Disember',
				'Last 7 Days Sales': 'Jualan 7 hari terakhir',
				Total: 'Jumlah',
				Outlet: 'Kedai',
				Epay: 'Epay',
				'Total Sales': 'Jumlah jualan',
				Idle: 'Terbiar',
				Running: 'Sedang berjalan',
				Offline: 'Di luar talian',
				'All status': 'Semua status',
				'Search Outlet': 'Cari Kedai',
				'Device status': 'Status peranti',
				Setting: 'Tetapan',
				Logout: 'Log keluar',
				'Sales Performance': 'Prestasi jualan',
				'All Outlets Yearly Sales': 'Semua jualan tahunan',
				'Total Annual Sales': 'Jumlah jualan tahunan',
				'Toggle Table': 'Meja togol',
				'Total Daily Sales': 'Jumlah jualan harian',
				Filter: 'Penapis',
				'Your Sales in': 'Jualan anda',
				'Sales (Coin)': 'Jualan (duit syiling)',
				'Sales (Epay)': 'Jualan (Epay)',
				'Total Sales By Outlet in': 'Jumlah jualan oleh outlet di',
				'Daily Sales by Outlet': 'Jualan harian oleh outlet',
				'Total Income': 'Jumlah pendapatan',
				'Highest Sales Outlet': 'Outlet jualan tertinggi',
				'Sales Per Day on': 'Jualan sehari',
				Token: 'Token',
				kg: 'kg',
				'Daily Total Sales': 'Jumlah jualan harian',
				'Daily Highest Sales Machine': 'Mesin Jualan Tertinggi Harian',
				'Sales Distribution % by Types': 'Pengagihan jualan % oleh jenis',
				'Sales Distribution % by Kg': 'Pengagihan Jualan % oleh KG',
				Month: 'Bulan',
				'Machine Name': 'Nama mesin',
				Method: 'Kaedah',
				Amount: 'Jumlah',
				Date: 'Tarikh',
				'Machine Utilization': 'Penggunaan Mesin',
				'All Outlets Machine Utilization': 'Semua Penggunaan Mesin',
				'Total Runtime of Machines By Outlet': 'Jumlah masa mesin berjalan mengikut outlet',
				'Total Run Time': 'Jumlah masa berjalan',
				'Highest Run Time Outlet': 'Outlet masa berjalan tertinggi',
				'Total Monthly Machine Utilization': 'Jumlah penggunaan mesin bulanan',
				Hours: 'jam',
				'Daily Machine Utilization at': 'Penggunaan mesin harian di',
				'Temperature Preference': 'Pilihan suhu',
				'Machine Utilization Time': 'Masa penggunaan mesin',
				cold: 'sejuk',
				warm: 'hangat',
				hot: 'Panas',
				other: 'yang lain',
				'washer idle': 'mesin basuh terbiar',
				'washer runtime': 'Runtime Washer',
				'dryer idle': 'pengering terbiar',
				'dryer runtime': 'Runtime pengering',
				'Remote Activation': 'Pengaktifan jauh',
				'Remote Activation / Machine Monitoring': 'Pengaktifan Jauh / Pemantauan Mesin',
				Online: 'Dalam talian',
				Pay: 'Bayar',
				'Loading...': 'Memuatkan...',
				Cancel: 'Batal',
				Remark: 'Kenyataan',
				Activation: 'Pengaktifan',
				Monitoring: 'Pemantauan',
				Stop: 'Henti',
				Start: 'Mula',
				'End At': 'Berakhir pada',
				'Start At': 'Bermula pada',
				On: 'Hidup',
				Off: 'Padam',
				'Transaction Record': 'Rekod Transaksi',
				'Transactions Record': 'Rekod Transaksi',
				'Choose Method': 'Pilih kaedah',
				Coin: 'Duit syiling',
				All: 'Semua',
				'Start Date': 'Tarikh mula',
				'End Date': 'Tarikh tamat',
				'Select Store': 'Pilih Kedai',
				Submit: 'Hantar',
				Search: 'Cari',
				'Outlet Name': 'Nama kedai',
				'Project Code': 'Kod projek',
				'Run Time (minutes)': 'Jalankan Masa (minit)',
				'Start Time': 'Masa mula',
				'End Time': 'Masa tamat',
				'Download CSV': 'Muat turun CSV',
				Today: 'Hari ini',
				Yesterday: 'Semalam',
				'Last seven Days': 'Tujuh hari terakhir',
				'Last thirty Days': 'Tiga puluh hari terakhir',
				'This month': 'Bulan ini',
				'Last month': 'Bulan lepas',
				page: 'halaman',
				'Click to sort ascending': 'Klik untuk menyusun menaik',
				'Click to sort descending': 'Klik untuk menyusun turun',
				'Browsable Report': 'Laporan yang boleh dilayari',
				Daily: 'Setiap hari',
				Monthly: 'Bulanan',
				Alipay: 'Alipay',
				Cash: 'Tunai',
				Discount: 'Diskaun',
				Summary: 'Ringkasan',
				'Download Excel': 'Muat turun Excel',
				'Sales Summary': 'Ringkasan Jualan',
				Operator: 'Pengendali',
				Status: 'Status',
				Time: 'Masa',
				'Transaction ID': 'ID Transaksi',
				Refund: 'Bayaran balik',
				'Epayment Transaction Record': 'Rekod Transaksi Epayment',
				'Epayment Record': 'Rekod Epayment',
				'Payee Account': 'Akaun penerima',
				'No data': 'Tiada data',
				'Error Code Record': 'Rekod Kod Ralat',
				'System Error Report': 'Laporan Ralat Sistem',
				Success: 'Kejayaan',
				'Manual Pay Record': 'Rekod Bayar Manual',
				'Manual Transaction Record': ' Rekod Transaksi Manual',
				'Bill Record': 'Rekod bil',
				'Machines Sales Transactions': 'Urus Niaga Jualan Mesin',
				'Machines Sales Transaction Report': 'Laporan Transaksi Jualan Mesin',
				'start time': 'masa mula',
				'end time': 'masa tamat',
				'Hourly Sales Transaction Report': 'Laporan Transaksi Jualan setiap jam',
				'Hourly Sales Transactions': 'Urus niaga jualan setiap jam',
				'Price Setting': 'Tetapan harga',
				Others: 'Yang lain',
				'Machine Logging': 'Mesin Logging',
				'Unassigned Outlets': 'Cawangan yang tidak ditetapkan',
				'Add Pricing': 'Tambah harga',
				Details: 'Perincian',
				'Assign To': 'Berikan kepada',
				'Assigned To': 'Tugaskan kepada',
				'Created On': 'Dicipta pada',
				Description: 'Penerangan',
				Name: 'Nama',
				'Add field': 'Tambah medan',
				'Assign outlets': 'Berikan kedai',
				Pricing: 'Harga',
				'Filter outlets': 'Tapis kedai',
				State: 'Negeri',
				Items: 'Item',
				'Select state': 'Pilih Negeri',
				Save: 'Simpan',
				Assigned: 'Ditugaskan',
				'Assigned outlets': 'Kedai yang diberikan',
				'Weight in KG': 'Berat dalam kg',
				'Cold Price': 'Harga sejuk',
				'Warm Price': 'Harga hangat',
				'Hot Price': 'Harga panas',
				'C-Run Time': 'Masa C-Run',
				'W-Run Time': 'Masa W-Run',
				'H-Run Time': 'Masa H-Run',
				'Default Mode': 'Mod lalai',
				'Weight kg': 'Berat kg',
				'Run Time RM1': 'Jalankan masa RM1',
				'Min Amount': 'Jumlah min',
				'Max Amount': 'Jumlah maksimum',
				'Initial Time': 'Masa awal',
				Promotion: 'Promosi',
				'Promotion Setting': 'Tetapan promosi',
				'Assign Promotion to Outlets': 'Tetapkan promosi kedai',
				'Add Promotion': 'Tambah promosi',
				'View/Update Promotion': 'Semak Promosi / Kemaskini',
				'Campaign Start Date': 'Tarikh permulaan kempen',
				'Campaign End Date': 'Tarikh akhir kempen',
				'Valid From': 'Sah dari',
				'Valid To': 'Sah ke',
				Monday: 'Isnin',
				Tuesday: 'Selasa',
				Wednesday: 'Rabu',
				Thursday: 'Khamis',
				Friday: 'Jumaat',
				Saturday: 'Sabtu',
				Sunday: 'Ahad',
				'Flat Rate: [cold, hot, warm]': 'Kadar rata: [sejuk, panas, hangat]',
				Percentage: 'Peratusan',
				'Rate In Percentage': 'Kadar peratusan',
				'Review Dashboard': 'Ringkasan maklum balas',
				'Total Reviews': 'Jumlah Maklum Balas',
				'Total Reviewer': 'Jumlah Penilai',
				'Outlet Cleanliness': 'Kebersihan outlet',
				'Washer Quality': 'Kualiti mesin basuh',
				'Dryer Quality': 'Kualiti pengering',
				'Service Quality': 'Kualiti servis',
				'Overall Score': 'Markah keseluruhan',
				'Select Outlet': 'Pilih kedai',
				'Select Outlets': 'Pilih kedai',
				'Select Year': 'Pilih tahun',
				'Select Month': 'Pilih bulan',
				'Clear All': 'Kosongkan semua',
				'Monthly Scores': 'Skor bulanan',
				Clean: 'Kebersihan',
				Service: 'Perkhidmatan',
				'All Reviews': 'Semua Maklum Balas',
				'No results found': 'Tiada keputusan dijumpai',
				'In progress': 'Sedang berjalan',
				Pending: 'Yang belum selesai',
				Resolve: 'Selesai',
				Resolved: 'Diselesaikan',
				'Add Record': 'Tambah rekod',
				'Select record type': 'Pilih Jenis Rekod',
				Issue: 'Isu',
				Maintenance: 'Penyelenggaraan',
				'Add Title': 'Tambah tajuk',
				Group: 'Kumpulan',
				'Selected Machines': 'Mesin terpilih',
				'Check All': 'Memeriksa semua',
				'Max length is 1000': 'Panjang maksimum ialah 1000',
				Upload: 'Muat naik',
				'Outlet Records': 'Rekod Outlet',
				Machines: 'Mesin',
				'Machine Issues': 'Masalah mesin',
				'Maintenance Records': 'Rekod penyelenggaraan',
				'Display Name': 'Nama paparan',
				Machine: 'Mesin',
				Title: 'Tajuk',
				'Machine Type': 'Jenis Mesin',
				Images: 'Imej',
				Update: 'Kemas kini',
				'Image Gallery': 'Galeri Imej',
				'Uploaded Images': 'Imej yang dimuat naik',
				Outlets: 'Kedai',
				Dryers: 'Pengering',
				Washers: 'Pencuci',
				'Add RM Config': 'Tambah konfigurasi RM',
				'List of Outlets': 'Senarai Kedai',
				Label: 'Label',
				'Client Secret': 'Rahsia pelanggan',
				'Client ID': 'ID Pelanggan',
				'RM Private Key': 'Kunci peribadi RM',
				'Create Outlet': 'Tambah kedai',
				'Machine Details': 'Butiran mesin',
				'Outlet Prefix': 'Awalan Kedai',
				'Full Name': 'Nama penuh',
				Brand: 'Jenama',
				Url: 'Url',
				Address: 'Alamat',
				City: 'Bandar',
				Postcode: 'Kod pos',
				'Rm Store ID': 'ID kedai RM',
				'Outlet Step': 'Unit token kedai',
				'No. Of Washers': 'Bilangan pencuci',
				'No. Of Dryers': 'Bilangan pengering',
				'No. Of Vendings': 'Bilangan mesin gedegang',
				'No. Of Bill Acceptors': 'Bilangan mesin token',
				'Machine Mode': 'Mod mesin',
				Cloud: 'Awan',
				'Ten Pulse': 'Sepuluh nadi',
				'Fifty Cent': 'Lima puluh sen',
				'Check Response': 'Semak respons',
				'Coin Rate Vending': 'Vending kadar duit syiling',
				'Payment Gateway Config': 'Konfigurasi gerbang pembayaran',
				'Select RM': 'Pilih RM',
				Next: 'Seterusnya',
				'Update Outlet': 'Kemas kini Outlet',
				'Update Machine': 'Mesin kemas kini',
				'List of Devices': 'Senarai peranti',
				QR: 'QR',
				'Qr Code': 'Kod QR',
				Download: 'Muat turun',
				'Download All': 'Muat turun semua',
				'Coin Rate': 'Kadar duit syiling',
				'One Run Time': 'Satu masa berjalan',
				'Select Washer Type': 'Pilih jenis mesin basuh',
				Type: 'Jenis',
				'Partner ID': 'ID rakan kongsi',
				'Slot 1 Name': 'Slot 1 Nama',
				'Slot 2 Name': 'Slot 2 Nama',
				'Slot 3 Name': 'Slot 3 Nama',
				'Slot 4 Name': 'Slot 4 Nama',
				'Slot 5 Name': 'Slot 5 Nama',
				'Slot 6 Name': 'Slot 6 Nama',
				Select: 'Pilih',
				Previous: 'Sebelumnya',
				Complete: 'Lengkap',
				'Operator Login': 'Log masuk pengendali',
				'Please enter your user name and password to login':
					'Sila masukkan nama pengguna dan kata laluan anda untuk log masuk',
				Login: 'Log masuk',
				'Terms & Conditions': 'Terma & Syarat',
				'Privacy & Policy': 'Privasi & Dasar',
				'Distributor Login': 'Log Masuk Pengedar',
				'Staff Login': 'Log masuk kakitangan',
				'Create your account': 'Cipta akaun anda',
				Username: 'Nama pengguna',
				Email: 'E -mel',
				'Brand Name': 'Jenama',
				Password: 'Kata laluan',
				'Confirm Password': 'Sahkan kata laluan',
				'Create Account': 'Buat akaun',
				'Already have an account?': 'Sudah mempunyai akaun?',
				'By signing up you agree to our': 'Dengan mendaftar anda bersetuju dengan kami',
				'Terms &': 'Syarat dan',
				Policy: 'Dasar',
				'Sign in': 'Log masuk',
				'Loyalty Admin': 'Pengurus Kesetiaan',
				'Utility Monitoring': 'Pemantauan Utiliti',
				//
				'Machine Sales Transaction Report': 'Laporan Transaksi Jualan Mesin',
				'Total Bills By Outlet': 'Jumlah bil oleh outlet',
				'Total Amount': 'Jumlah keseluruhan',
				'Actual Amount': 'Jumlah sebenar',
				'Sales by Machine Type': 'Jualan mengikut Jenis Mesin',
				'Are you sure to delete this pricing?': 'Adakah anda pasti memadamkan harga ini?',
				'Successfully created': 'Berjaya dicipta',
				'Successfully assigned price': 'Harga yang berjaya ditugaskan',
				'Pricing Details': 'Butiran harga',
				Detergent: 'Bahan pencuci',
				Softener: 'Pelembut',
				'Laundry Bag': 'Beg pakaian',
				Bleach: 'Peluntur',
				'Dry Softener': 'Pelembut kering',
				Yes: 'Ya',
				No: 'Tidak',
				'Select date': 'Pilih tarikh',
				'Select time': 'Pilih masa',
				'Rate in flat rate': 'Kadar dalam kadar rata',
				Action: 'Tindakan',
				Number: 'Nombor',
				Comment: 'Komen',

				'Activation Date': 'Tarikh Pengaktifan',
				'Warranty Year': 'Tahun Jaminan',
				'Warranty Status': 'Status Jaminan',
				'Serial Number': 'Nombor Siri',
				'Add Warranty': 'Tambah Jaminan',
				'View Warranty': 'Lihat Jaminan',
				'Records Details': 'Butiran Rekod',
				'Add Year': 'Tambah Tahun',
				'Add Serial Number': 'Tambah Nombor Siri',
				'years limited': 'tahun terhad',
				warranty: 'jaminan',
				certificate: 'sijil',

				'Coin Collection': 'Koleksi Duit Syiling',
				'No Outlet': 'Tiada Kedai',
				'Cutoff Date': 'Tarikh Cutoff',
				'Submit Date': 'Hantar Tarikh',
				'Assign Machine': 'Berikan Mesin',
				'Select All': 'Pilih Semua',
				'PIC Role': 'Peranan PIC',
				'PIC ID': 'ID PIC',
				'PIC Name': 'Nama PIC',
				Completed: 'Selesai',
				'Start Collection': 'Koleksi Mula',
				'Create Machine Group': 'Buat Kumpulan Mesin',
				'Machine List': 'Senarai Mesin',
				'Cutoff Machine': 'Mesin Cutoff',
				'Submit Amount': 'Hantar Jumlah',
				'Submitted Amount': 'Jumlah yang Dihantar',
				'Bulk Machines Cutoff': 'Mesin Pukal Cutoff',
				'Enable Create Group Machine': 'Dayakan Mesin Kumpulan Buat',
				'Add Group': 'Tambah Kumpulan',
				Add: 'Tambah',
				View: 'Lihat',
				'Top Outlets': 'Kedai Atas',
				'View Outlet': 'Lihat Kedai',
				'Outlet Online Status': 'Status atas talian kedai',
				'Machine Online Status': 'Status atas talian mesin',
				Required: 'Diperlukan',

				'Add Machine Details': 'Tambah Butiran Mesin',
				'Manage User': 'Menguruskan Pengguna',
				'RM Configuration': 'Konfigurasi RM',
				'Create RM Config': 'Buat Konfigurasi RM',
				'Update Machine Details': 'Kemas Kini Butiran Mesin',
				'Receive Mail': 'Terima Mel',
				Active: 'Aktif',
				'Created At': 'Dicipta Di',
				'Add User': 'Tambah Pengguna',
				User: 'Pengguna',
				Staff: 'Kakitangan',
				'Select user type': 'Pilih Jenis Pengguna',
				'Select Operator': 'Pilih Operator',
				'Select Role': 'Pilih Peranan',
				Activated: 'Diaktifkan',
				Deactivated: 'Dinyahaktifkan',
				'Outlets Permissions': 'Keizinan Kedai',
				'Update User': 'Kemas Kini Pengguna',
				'Created Role': 'Peranan Yang Dicipta',
				'Created By': 'Dicipta Oleh',
				'Add Role': 'Tambah Peranan',
				Optional: 'Pilihan',
				'Laundro Permissions': 'Keizinan Laundro',
				'Edit Role': 'Meyunting Peranan',
				'Select Dryer Type': 'Pilih Jenis Pengering',
				'All Users': 'Semua Pengguna',
				'Roles List': 'Senarai Peranan',
				'The two passwords that you entered do not match!': 'Dua kata laluan yang anda masukkan tidak sepadan!',
				'Add Machine Group': 'Tambah kumpulan mesin',
				'Edit Machine Group': 'Edit Kumpulan Mesin',
				Distributor: 'Pengedar',
				Bill: 'Bil',
				'Meter Reading': 'Bacaan Meter',
				'Not Assigned': 'Tidak ditugaskan',
			},
		},
	},
	lng: localStorage.getItem('laundro-language') || 'en',
});

export default i18n;

// Submitted Coin Amount
// Submitted Bill Amount
// Bill Collection
