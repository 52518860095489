import { getToken, returnError } from './helperFunc';
import {
  getDropPriceListAction,
  addDropPricingAction,
  assignDropPricingAction,
  editDropPricingAction,
  deleteDropPricingAction,
} from './requestEndpoints';

import { LOGOUT_SUCCESS } from './userReducer';

const SET_DROP_PRICE_LOADING = 'SET_DROP_PRICE_LOADING';
const CLEAR_DROP_PRICE_ERROR = 'CLEAR_DROP_PRICE_ERROR';

const ADD_DROP_PRICING_SUCCESS = 'ADD_DROP_PRICING_SUCCESS';
const ADD_DROP_PRICING_FAIL = 'ADD_DROP_PRICING_FAIL';

const ASSIGN_DROP_PRICING_SUCCESS = 'ASSIGN_DROP_PRICING_SUCCESS';
const ASSIGN_DROP_PRICING_FAIL = 'ASSIGN_DROP_PRICING_FAIL';

const GET_DROP_PRICE_LIST_SUCCESS = 'GET_DROP_PRICE_LIST_SUCCESS';
const GET_DROP_PRICE_LIST_FAIL = 'GET_DROP_PRICE_LIST_FAIL';

const DELETE_DROP_PRICING_SUCCESS = 'DELETE_DROP_PRICING_SUCCESS';
const DELETE_DROP_PRICING_FAIL = 'DELETE_DROP_PRICING_FAIL';

const UPDATE_DROP_PRICING_SUCCESS = 'UPDATE_DROP_PRICING_SUCCESS';
const UPDATE_DROP_PRICING_FAIL = 'UPDATE_DROP_PRICING_FAIL';

// FOR DROP_PRICE SETTING

export const getDropPriceList = (operatorId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_DROP_PRICE_ERROR });
    dispatch({ type: SET_DROP_PRICE_LOADING });

    const { user } = getState();
    // console.log('user', user);
    const token = getToken(user);

    const { data } = await getDropPriceListAction(operatorId, token);

    dispatch({ type: GET_DROP_PRICE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_DROP_PRICE_LIST_FAIL, payload: returnError(error) });
  }
};

export const addDropPricing = (newPrice) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_DROP_PRICE_ERROR });
    dispatch({ type: SET_DROP_PRICE_LOADING });

    const { user } = getState();
    // console.log('user', user);
    const token = getToken(user);

    const { data } = await addDropPricingAction(newPrice, token);

    dispatch({ type: ADD_DROP_PRICING_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ADD_DROP_PRICING_FAIL, payload: returnError(error) });
  }
};

export const assignDropPricing = (newPrice) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_DROP_PRICE_ERROR });
    dispatch({ type: SET_DROP_PRICE_LOADING });

    const { user } = getState();
    // console.log('user', user);
    const token = getToken(user);

    const { data } = await assignDropPricingAction(newPrice, token);

    dispatch({ type: ASSIGN_DROP_PRICING_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ASSIGN_DROP_PRICING_FAIL, payload: returnError(error) });
  }
};

export const updateDropPricing = (updateData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_DROP_PRICE_ERROR });
    dispatch({ type: SET_DROP_PRICE_LOADING });

    const { user } = getState();
    // console.log('user', user);
    const token = getToken(user);

    const { data } = await editDropPricingAction(updateData, token);

    dispatch({ type: UPDATE_DROP_PRICING_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_DROP_PRICING_FAIL, payload: returnError(error) });
  }
};

export const deleteDropPricing = (resBody) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_DROP_PRICE_ERROR });
    dispatch({ type: SET_DROP_PRICE_LOADING });

    const { user } = getState();
    // console.log('user', user);
    const token = getToken(user);
    // console.log('token', token);
    const { data } = await deleteDropPricingAction(resBody, token);

    dispatch({ type: DELETE_DROP_PRICING_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: DELETE_DROP_PRICING_FAIL, payload: returnError(error) });
  }
};

const initialState = {
  language: 'en',
  priceList: null,
  loading: false,
  priceLists: [],
};

export const priceDropSettingReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_DROP_PRICE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_DROP_PRICE_ERROR:
      return {
        ...state,
        error: null,
      };

    case GET_DROP_PRICE_LIST_SUCCESS:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        priceLists: action.payload.data,
        loading: false,
        error: null,
      };
    case ADD_DROP_PRICING_SUCCESS:
      return {
        ...state,
        price: action.payload.data,
        loading: false,
        error: null,
        success: true,
      };
    case ASSIGN_DROP_PRICING_SUCCESS:
      return {
        ...state,
        assigned: action.payload.data,
        loading: false,
        error: null,
        success: true,
      };
    case UPDATE_DROP_PRICING_SUCCESS:
      return {
        ...state,
        priceUpdated: action.payload.data,
        loading: false,
        error: null,
        success: true,
      };
    case DELETE_DROP_PRICING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        removed: true,
      };
    case LOGOUT_SUCCESS:
      return initialState;

    case GET_DROP_PRICE_LIST_FAIL:
    case ASSIGN_DROP_PRICING_FAIL:
    case UPDATE_DROP_PRICING_FAIL:
    case DELETE_DROP_PRICING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
